/* eslint-disable jsx-a11y/anchor-is-valid */ // eslint-disable-line
import React, { useEffect } from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { linesEnter, } from 'animations/mandala';

import './style.scss';

const MandalaSvg = ({ coords, location }) => {

  useEffect(() => {
    const lines = document.querySelector('.tweenMax-lines');
    linesEnter(lines);
  }, [location.pathname]);

  return(
    <div id="mandala-container" className='tweenMax-mandala'>
      <div className="mandala">
        <svg id="rotate" viewBox="0 0 248.527 248.527">
          <g id="cirkels">
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="238.518" cy="128.713" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="238.352" cy="132.458" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M238.048,136.455c-0.141-0.014-0.249-0.141-0.238-0.283s0.136-0.246,0.277-0.233
						c0.142,0.014,0.249,0.14,0.238,0.283C238.314,136.364,238.19,136.469,238.048,136.455z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="237.626" cy="139.92" r="0.259" d="M5 20 l215 0" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M237.05,143.885c-0.14-0.023-0.235-0.156-0.21-0.296c0.025-0.14,0.161-0.235,0.301-0.213
						c0.142,0.023,0.235,0.155,0.21,0.296C237.326,143.813,237.191,143.909,237.05,143.885z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M236.391,147.577c-0.139-0.028-0.233-0.165-0.208-0.305c0.025-0.14,0.16-0.231,0.299-0.204
						c0.141,0.027,0.234,0.164,0.208,0.305C236.666,147.513,236.531,147.604,236.391,147.577z" />
                </g>
                <g>
                  <a className="" id="p1">
                    <circle fill="#FFFFFF" className="hoverable" cx="235.631" cy="150.983" r="0.259"/>
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M234.664,154.872c-0.137-0.037-0.218-0.179-0.179-0.316c0.039-0.137,0.183-0.218,0.32-0.182
						c0.139,0.037,0.219,0.178,0.18,0.315C234.945,154.828,234.802,154.91,234.664,154.872z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M233.639,158.479c-0.136-0.042-0.216-0.188-0.177-0.324c0.039-0.137,0.182-0.214,0.318-0.174
						c0.137,0.042,0.216,0.187,0.177,0.324S233.776,158.521,233.639,158.479z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="232.543" cy="161.793" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M231.191,165.565c-0.133-0.051-0.2-0.2-0.147-0.332c0.052-0.133,0.204-0.199,0.337-0.149
						c0.134,0.05,0.2,0.199,0.147,0.332C231.475,165.55,231.325,165.616,231.191,165.565z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M229.815,169.054c-0.131-0.055-0.196-0.208-0.144-0.34c0.052-0.132,0.203-0.195,0.333-0.141
						c0.132,0.055,0.197,0.207,0.144,0.34C230.097,169.046,229.947,169.109,229.815,169.054z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="228.392" cy="172.241" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="226.786" cy="175.628" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M224.958,179.196c-0.125-0.067-0.175-0.226-0.11-0.353c0.065-0.126,0.22-0.174,0.346-0.107
						c0.126,0.067,0.175,0.226,0.11,0.353C225.239,179.216,225.084,179.264,224.958,179.196z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="223.222" cy="182.224" r="0.259" />
                </g>
                <g>
                  <a className="" id="p2">
                    <circle fill="#FFFFFF" className="hoverable" cx="221.283" cy="185.433" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M219.111,188.801c-0.118-0.08-0.146-0.239-0.065-0.356c0.077-0.12,0.236-0.152,0.354-0.073
						c0.119,0.08,0.152,0.242,0.075,0.362C219.394,188.853,219.229,188.881,219.111,188.801z" />	
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.088" cy="191.646" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.838" cy="194.645" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M212.34,197.779c-0.109-0.091-0.123-0.253-0.03-0.361c0.093-0.107,0.259-0.121,0.369-0.03
						s0.124,0.252,0.03,0.36C212.615,197.857,212.45,197.871,212.34,197.779z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="210.054" cy="200.418" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.515" cy="203.176" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M204.718,206.045c-0.1-0.101-0.098-0.264,0.005-0.363c0.103-0.098,0.269-0.095,0.369,0.006
							c0.101,0.102,0.098,0.264-0.005,0.362C204.983,206.15,204.818,206.147,204.718,206.045z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M202.004,208.633c-0.096-0.104-0.092-0.27,0.011-0.368s0.266-0.093,0.363,0.011
						c0.098,0.105,0.092,0.27-0.011,0.369S202.101,208.738,202.004,208.633z" />
                </g>
                <g>
                  <a id="p3" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="199.389" cy="210.945" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M196.32,213.521c-0.089-0.11-0.071-0.272,0.041-0.361c0.112-0.088,0.276-0.069,0.366,0.042
						c0.09,0.111,0.072,0.272-0.041,0.361C196.573,213.651,196.41,213.633,196.32,213.521z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.372,215.839c-0.085-0.114-0.065-0.278,0.047-0.366c0.112-0.088,0.273-0.066,0.359,0.046
						c0.086,0.114,0.065,0.278-0.047,0.366C193.619,215.974,193.458,215.953,193.372,215.839z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.538" cy="217.877" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="187.444" cy="219.995" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M184.07,222.158c-0.074-0.122-0.037-0.284,0.083-0.36c0.12-0.077,0.278-0.04,0.353,0.081
						c0.075,0.122,0.038,0.283-0.083,0.36C184.302,222.316,184.144,222.28,184.07,222.158z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.045" cy="223.901" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.755" cy="225.698" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M174.183,227.516c-0.062-0.129-0.006-0.279,0.122-0.342c0.127-0.065,0.28-0.013,0.343,0.115
						c0.062,0.129,0.009,0.286-0.118,0.351C174.401,227.703,174.244,227.644,174.183,227.516z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.002" cy="228.954" r="0.259" />
                </g>
                <g>
                  <a id="p4" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="167.548" cy="230.411" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M163.812,231.862c-0.048-0.134,0.021-0.281,0.156-0.326c0.136-0.043,0.283,0.022,0.332,0.156
						c0.049,0.134-0.019,0.287-0.155,0.331C164.01,232.068,163.86,231.996,163.812,231.862z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="160.505" cy="232.98" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="156.922" cy="234.083" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M153.06,235.153c-0.035-0.138,0.049-0.277,0.188-0.309c0.139-0.032,0.28,0.055,0.316,0.192
						c0.036,0.139-0.049,0.277-0.188,0.309C153.236,235.378,153.095,235.292,153.06,235.153z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.658" cy="235.938" r="0.259" d="M5 20 l215 0" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.983" cy="236.678" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M142.034,237.36c-0.021-0.141,0.077-0.271,0.218-0.289c0.141-0.017,0.273,0.082,0.294,0.22
						c0.022,0.143-0.075,0.275-0.217,0.292C142.187,237.601,142.055,237.501,142.034,237.36z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.571" cy="237.8" r="0.259" />
                </g>
                <g>
                  <a id="p5" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="134.84" cy="238.173" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M130.843,238.466c-0.007-0.143,0.103-0.259,0.245-0.268c0.142-0.011,0.263,0.097,0.271,0.239
						c0.008,0.143-0.102,0.268-0.245,0.278C130.972,238.724,130.85,238.608,130.843,238.466z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.353" cy="238.554" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.605" cy="238.568" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M119.598,238.466c0.007-0.142,0.128-0.256,0.271-0.252c0.142,0.003,0.252,0.123,0.247,0.265
						c-0.006,0.143-0.127,0.255-0.271,0.252C119.701,238.728,119.59,238.609,119.598,238.466z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.116" cy="238.212" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.383" cy="237.865" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M108.407,237.36c0.021-0.141,0.153-0.242,0.294-0.224c0.141,0.018,0.239,0.148,0.219,0.289
						c-0.02,0.142-0.152,0.242-0.294,0.224S108.386,237.501,108.407,237.36z" />
                </g>
                <g>
                  <a id="p6" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="104.967" cy="236.769" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M101.035,235.997c0.03-0.139,0.167-0.227,0.306-0.195c0.139,0.032,0.227,0.172,0.198,0.312
						c-0.03,0.14-0.167,0.227-0.306,0.195C101.093,236.276,101.004,236.137,101.035,235.997z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M97.381,235.153c0.035-0.138,0.176-0.225,0.315-0.193c0.139,0.032,0.223,0.171,0.189,0.31
						c-0.034,0.139-0.175,0.225-0.314,0.193C97.431,235.431,97.346,235.292,97.381,235.153z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.016" cy="234.224" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M90.181,233.063c0.044-0.135,0.189-0.209,0.325-0.164c0.135,0.046,0.208,0.194,0.165,0.33
						c-0.043,0.136-0.188,0.209-0.324,0.163C90.211,233.347,90.137,233.2,90.181,233.063z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M86.629,231.862c0.048-0.134,0.197-0.207,0.333-0.161c0.135,0.045,0.205,0.192,0.157,0.326
						c-0.048,0.135-0.197,0.207-0.333,0.161C86.651,232.142,86.58,231.996,86.629,231.862z" />
                </g>
                <g>
                  <a id="p7" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="83.374" cy="230.6" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.91" cy="229.167" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M76.258,227.516c0.062-0.128,0.217-0.186,0.347-0.127c0.129,0.059,0.185,0.211,0.124,0.34
						c-0.061,0.129-0.216,0.186-0.347,0.127C76.251,227.797,76.196,227.645,76.258,227.516z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.146" cy="225.933" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="69.844" cy="224.159" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M66.371,222.158c0.073-0.12,0.232-0.16,0.357-0.09c0.123,0.071,0.163,0.229,0.09,0.35
						c-0.074,0.123-0.234,0.164-0.358,0.093C66.334,222.441,66.295,222.281,66.371,222.158z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="63.432" cy="220.274" r="0.259" />
                </g>
                <g>
                  <a id="p8" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="60.324" cy="218.177" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M57.069,215.839c0.085-0.114,0.247-0.135,0.358-0.047c0.11,0.09,0.137,0.248,0.052,0.362
							c-0.085,0.114-0.251,0.143-0.362,0.052C57.005,216.117,56.983,215.953,57.069,215.839z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="54.324" cy="213.682" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.442" cy="211.284" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M48.437,208.633c0.096-0.104,0.259-0.11,0.362-0.012s0.108,0.264,0.012,0.369
						c-0.096,0.106-0.258,0.111-0.362,0.013C48.346,208.903,48.34,208.738,48.437,208.633z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="45.91" cy="206.224" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="43.282" cy="203.55" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M40.556,200.613c0.106-0.095,0.269-0.085,0.362,0.023c0.093,0.107,0.082,0.273-0.023,0.368
						c-0.106,0.096-0.269,0.085-0.362-0.022C40.438,200.873,40.449,200.708,40.556,200.613z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.101,197.779c0.109-0.091,0.275-0.078,0.368,0.03c0.093,0.107,0.079,0.27-0.029,0.361
							c-0.109,0.092-0.274,0.079-0.368-0.029S37.991,197.871,38.101,197.779z" />
                </g>
                <g>
                  <a id="p9" className="">					
                    <circle fill="#FFFFFF" className="hoverable" cx="35.923" cy="195.052" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.652" cy="192.068" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.33,188.801c0.118-0.08,0.281-0.051,0.364,0.065c0.083,0.116,0.053,0.276-0.064,0.356
						c-0.118,0.081-0.281,0.052-0.364-0.064C31.183,189.041,31.211,188.882,31.33,188.801z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.437" cy="185.868" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="27.477" cy="182.673" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M25.482,179.196c0.124-0.067,0.28-0.021,0.353,0.102c0.071,0.124,0.026,0.28-0.099,0.348
						c-0.125,0.069-0.285,0.023-0.356-0.101C25.307,179.423,25.355,179.265,25.482,179.196z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="23.891" cy="176.089" r="0.259" />
                </g>
                <g>
                  <a id="p10" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="22.262" cy="172.713" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M20.625,169.054c0.131-0.055,0.281,0.008,0.333,0.14s-0.003,0.281-0.135,0.337
						c-0.13,0.056-0.289-0.003-0.342-0.136C20.429,169.262,20.493,169.109,20.625,169.054z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="19.344" cy="165.806" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="18.063" cy="162.283" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M16.801,158.479c0.136-0.042,0.279,0.035,0.318,0.172c0.039,0.137-0.041,0.283-0.175,0.325
						c-0.138,0.043-0.281-0.034-0.32-0.172S16.665,158.521,16.801,158.479z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="15.847" cy="155.121" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="14.926" cy="151.487" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M14.049,147.577c0.139-0.028,0.274,0.063,0.299,0.204c0.025,0.14-0.07,0.277-0.208,0.306
						c-0.141,0.029-0.274-0.062-0.299-0.203C13.816,147.742,13.909,147.604,14.049,147.577z" />
                </g>
                <g>
                  <a id="p11" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="13.436" cy="144.14" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="12.879" cy="140.433" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M12.393,136.455c0.141-0.014,0.266,0.09,0.277,0.232c0.008,0.142-0.086,0.268-0.23,0.283
						c-0.14,0.014-0.277-0.088-0.285-0.231C12.144,136.596,12.25,136.469,12.393,136.455z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="12.128" cy="132.974" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="11.936" cy="129.23" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M11.834,125.223c0.142,0,0.262,0.116,0.265,0.258c0.004,0.142-0.11,0.258-0.252,0.259
						c-0.143,0-0.262-0.115-0.266-0.258C11.579,125.339,11.691,125.223,11.834,125.223z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="11.923" cy="121.733" r="0.259" />
                </g>
                <g>
                  <a id="p12" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="12.088" cy="117.988" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M12.393,113.991c0.141,0.014,0.249,0.141,0.238,0.282c-0.011,0.142-0.136,0.247-0.277,0.233
						c-0.142-0.014-0.249-0.14-0.238-0.283S12.25,113.977,12.393,113.991z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="12.814" cy="110.526" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M13.39,106.56c0.14,0.023,0.235,0.156,0.21,0.296s-0.161,0.235-0.301,0.213
						c-0.142-0.022-0.235-0.155-0.21-0.296C13.114,106.632,13.249,106.537,13.39,106.56z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M14.049,102.869c0.139,0.028,0.233,0.165,0.208,0.305c-0.025,0.14-0.16,0.231-0.299,0.204
						c-0.141-0.027-0.234-0.164-0.208-0.305C13.775,102.932,13.909,102.841,14.049,102.869z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="14.81" cy="99.462" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M15.777,95.573c0.137,0.037,0.218,0.179,0.179,0.316c-0.039,0.137-0.183,0.218-0.32,0.182
						c-0.139-0.037-0.219-0.178-0.18-0.315C15.495,95.617,15.639,95.536,15.777,95.573z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M16.801,91.966c0.136,0.042,0.216,0.187,0.177,0.324c-0.039,0.137-0.182,0.214-0.318,0.173
						c-0.137-0.042-0.216-0.187-0.177-0.324S16.665,91.924,16.801,91.966z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="17.897" cy="88.652" r="0.259" />
                </g>
                <g>
                  <a id="p13" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="19.155" cy="85.121" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M20.625,81.392c0.131,0.055,0.196,0.208,0.144,0.34c-0.052,0.132-0.202,0.195-0.333,0.141
							c-0.132-0.055-0.197-0.207-0.144-0.34C20.344,81.399,20.493,81.336,20.625,81.392z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="22.049" cy="78.204" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="23.655" cy="74.817" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M25.482,71.25c0.125,0.068,0.175,0.226,0.11,0.353s-0.22,0.174-0.346,0.107
						c-0.126-0.068-0.175-0.226-0.11-0.353C25.202,71.229,25.356,71.181,25.482,71.25z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="27.219" cy="68.221" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.157" cy="65.013" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.33,61.644c0.118,0.08,0.146,0.239,0.066,0.356c-0.077,0.12-0.236,0.152-0.354,0.073
						c-0.119-0.08-0.152-0.242-0.075-0.362C31.047,61.592,31.212,61.564,31.33,61.644z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.353" cy="58.799" r="0.258" />
                </g>
                <g>
                  <a id="p14" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="35.603" cy="55.801" r="0.258" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.101,52.667c0.109,0.091,0.123,0.253,0.03,0.361s-0.259,0.121-0.368,0.03
						c-0.11-0.091-0.124-0.252-0.03-0.361S37.991,52.575,38.101,52.667z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="40.386" cy="50.028" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="42.926" cy="47.27" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M45.723,44.4c0.1,0.102,0.098,0.264-0.005,0.363c-0.103,0.098-0.269,0.095-0.369-0.006
						c-0.101-0.102-0.099-0.264,0.005-0.362C45.458,44.295,45.623,44.298,45.723,44.4z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M48.437,41.812c0.096,0.104,0.091,0.27-0.012,0.369c-0.103,0.098-0.266,0.092-0.363-0.012
						c-0.098-0.105-0.092-0.27,0.011-0.368C48.178,41.702,48.34,41.707,48.437,41.812z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.052" cy="39.5" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M54.121,36.924c0.089,0.11,0.072,0.273-0.041,0.361c-0.112,0.088-0.276,0.069-0.366-0.042
						c-0.09-0.111-0.072-0.272,0.04-0.361C53.867,36.794,54.031,36.812,54.121,36.924z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M57.069,34.607c0.085,0.113,0.064,0.278-0.047,0.366c-0.112,0.088-0.273,0.066-0.359-0.047
						c-0.087-0.114-0.065-0.278,0.047-0.366C56.822,34.472,56.983,34.493,57.069,34.607z" />
                </g>
                <g>
                  <a id="p15" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="59.903" cy="32.568" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="62.996" cy="30.451" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M66.371,28.288c0.074,0.121,0.037,0.283-0.083,0.36c-0.12,0.077-0.278,0.04-0.353-0.082
						c-0.075-0.122-0.038-0.283,0.083-0.36C66.139,28.129,66.296,28.165,66.371,28.288z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="69.396" cy="26.544" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.686" cy="24.748" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M76.258,22.93c0.062,0.129,0.005,0.279-0.123,0.342c-0.126,0.065-0.28,0.013-0.342-0.115
						c-0.062-0.128-0.01-0.286,0.118-0.351C76.039,22.742,76.196,22.802,76.258,22.93z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.439" cy="21.492" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="82.893" cy="20.035" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M86.629,18.584c0.048,0.133-0.021,0.28-0.157,0.326c-0.135,0.043-0.283-0.022-0.332-0.155
							c-0.05-0.135,0.018-0.287,0.154-0.331C86.431,18.377,86.58,18.449,86.629,18.584z" />
                </g>
                <g>
                  <a id="p16" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="89.936" cy="17.465" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="93.518" cy="16.362" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M97.381,15.292c0.035,0.138-0.049,0.277-0.188,0.309c-0.139,0.032-0.28-0.055-0.315-0.192
						c-0.036-0.139,0.048-0.276,0.188-0.309C97.204,15.067,97.346,15.153,97.381,15.292z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.782" cy="14.507" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.457" cy="13.768" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M108.407,13.086c0.021,0.14-0.077,0.271-0.218,0.288c-0.142,0.017-0.273-0.082-0.295-0.22
						c-0.022-0.143,0.075-0.275,0.217-0.292C108.253,12.844,108.386,12.944,108.407,13.086z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.87" cy="12.646" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.6" cy="12.272" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M119.597,11.979c0.007,0.143-0.103,0.259-0.245,0.268c-0.142,0.011-0.263-0.097-0.271-0.239
						c-0.008-0.143,0.102-0.268,0.245-0.278C119.469,11.721,119.59,11.837,119.597,11.979z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.087" cy="11.891" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="126.836" cy="11.878" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M130.843,11.979c-0.007,0.142-0.128,0.256-0.271,0.252c-0.142-0.003-0.252-0.123-0.246-0.265
						c0.006-0.143,0.127-0.255,0.271-0.252C130.74,11.718,130.85,11.836,130.843,11.979z" />
                </g>
                <g>
                  <a id="p17" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="134.325" cy="12.233" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M138.314,12.613c-0.017,0.141-0.145,0.243-0.286,0.225s-0.243-0.148-0.228-0.29
							c0.016-0.142,0.144-0.243,0.285-0.225C138.228,12.341,138.33,12.471,138.314,12.613z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M142.034,13.085c-0.021,0.141-0.153,0.242-0.294,0.224c-0.141-0.018-0.239-0.148-0.219-0.289
						c0.021-0.142,0.152-0.242,0.294-0.224S142.055,12.944,142.034,13.085z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.474" cy="13.677" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M149.406,14.449c-0.03,0.139-0.168,0.227-0.307,0.195c-0.139-0.032-0.227-0.172-0.198-0.312
						c0.03-0.14,0.167-0.227,0.307-0.195S149.437,14.309,149.406,14.449z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M153.06,15.292c-0.035,0.138-0.176,0.225-0.315,0.193c-0.138-0.032-0.223-0.171-0.189-0.31
						c0.035-0.139,0.175-0.225,0.315-0.193S153.095,15.153,153.06,15.292z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="156.425" cy="16.221" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M160.26,17.382c-0.044,0.135-0.189,0.209-0.324,0.164c-0.135-0.046-0.209-0.194-0.166-0.33
						c0.043-0.136,0.188-0.209,0.324-0.163S160.304,17.246,160.26,17.382z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M163.812,18.583c-0.048,0.134-0.197,0.207-0.333,0.161c-0.135-0.045-0.205-0.192-0.157-0.326
						c0.048-0.135,0.197-0.207,0.333-0.161S163.86,18.449,163.812,18.583z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="167.067" cy="19.845" r="0.259" />
                </g>
                <g>
                  <a id="p18" className="">
                    <circle fill="#FFFFFF" className="hoverable"cx="170.531" cy="21.279" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M174.183,22.93c-0.062,0.128-0.217,0.186-0.347,0.127c-0.129-0.059-0.185-0.211-0.124-0.34
								c0.062-0.129,0.217-0.186,0.347-0.127C174.189,22.648,174.245,22.801,174.183,22.93z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.295" cy="24.512" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="180.597" cy="26.287" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M184.07,28.287c-0.073,0.12-0.232,0.16-0.356,0.09c-0.124-0.071-0.164-0.229-0.09-0.35
						c0.074-0.123,0.234-0.164,0.358-0.093C184.106,28.004,184.145,28.164,184.07,28.287z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="187.009" cy="30.171" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.116" cy="32.269" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.372,34.606c-0.086,0.114-0.247,0.136-0.359,0.047c-0.11-0.09-0.137-0.248-0.051-0.362
						c0.085-0.114,0.251-0.143,0.362-0.052C193.436,34.328,193.458,34.492,193.372,34.606z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="196.116" cy="36.764" r="0.259" />
                </g>
                <g>
                  <a id="p19" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="198.998" cy="39.161" r="0.258" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M202.003,41.812c-0.096,0.104-0.259,0.11-0.362,0.012s-0.108-0.264-0.012-0.369
						c0.096-0.106,0.258-0.111,0.362-0.012C202.095,41.542,202.101,41.707,202.003,41.812z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M204.718,44.4c-0.1,0.101-0.265,0.105-0.369,0.006c-0.103-0.098-0.105-0.262-0.006-0.363
						c0.1-0.103,0.265-0.105,0.368-0.006C204.815,44.135,204.818,44.298,204.718,44.4z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.158" cy="46.895" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.885,49.832c-0.106,0.095-0.269,0.085-0.362-0.023c-0.093-0.107-0.082-0.273,0.023-0.368
						c0.106-0.096,0.269-0.085,0.362,0.022C210.002,49.572,209.992,49.737,209.885,49.832z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M212.34,52.667c-0.109,0.091-0.274,0.078-0.368-0.029c-0.093-0.108-0.08-0.27,0.029-0.362
						c0.109-0.092,0.274-0.079,0.368,0.029C212.462,52.413,212.45,52.575,212.34,52.667z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.518" cy="55.394" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M216.938,58.588c-0.115,0.084-0.276,0.058-0.359-0.058c-0.083-0.116-0.056-0.279,0.059-0.364
						c0.115-0.084,0.276-0.059,0.358,0.058C217.08,58.341,217.054,58.504,216.938,58.588z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M219.111,61.644c-0.118,0.08-0.281,0.051-0.364-0.065s-0.053-0.276,0.064-0.356
						c0.118-0.081,0.281-0.052,0.364,0.064C219.258,61.404,219.229,61.563,219.111,61.644z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.004" cy="64.577" r="0.259" />
                </g>
                <g>
                  <a id="p20" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="222.964" cy="67.772" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M224.958,71.25c-0.124,0.067-0.28,0.02-0.353-0.103c-0.071-0.123-0.026-0.28,0.099-0.348
							c0.125-0.068,0.285-0.023,0.356,0.101C225.133,71.022,225.085,71.181,224.958,71.25z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="226.55" cy="74.357" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="228.179" cy="77.733" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M229.815,81.392c-0.131,0.055-0.281-0.007-0.333-0.14c-0.052-0.132,0.003-0.281,0.135-0.337
						c0.13-0.056,0.289,0.003,0.342,0.136C230.011,81.184,229.947,81.336,229.815,81.392z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="231.096" cy="84.639" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="232.377" cy="88.162" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M233.639,91.966c-0.136,0.042-0.279-0.036-0.318-0.173c-0.039-0.137,0.041-0.283,0.175-0.324
						c0.138-0.043,0.281,0.034,0.32,0.171C233.855,91.779,233.776,91.924,233.639,91.966z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="234.593" cy="95.324" r="0.259" />
                </g>
                <g>
                  <a id="p21" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="235.515" cy="98.958" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M236.391,102.869c-0.139,0.028-0.274-0.063-0.299-0.203c-0.025-0.14,0.069-0.277,0.208-0.306
						c0.141-0.029,0.274,0.062,0.299,0.203C236.625,102.704,236.531,102.841,236.391,102.869z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="237.005" cy="106.305" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="237.561" cy="110.012" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M238.048,113.991c-0.141,0.014-0.266-0.09-0.277-0.232c-0.008-0.142,0.086-0.268,0.23-0.283
						c0.14-0.015,0.277,0.088,0.285,0.231C238.297,113.85,238.19,113.977,238.048,113.991z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="238.313" cy="117.472" r="0.259" />
                </g>
                <g>
                  <a id="p22" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="238.505" cy="121.216" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M238.606,125.223c-0.142,0-0.262-0.116-0.265-0.258c-0.004-0.143,0.11-0.259,0.252-0.259
							c0.143,0,0.262,0.115,0.266,0.258C238.862,125.106,238.749,125.223,238.606,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="227.18" cy="128.713" r="0.259" />
                </g>
                <g>
                  <a id="p23" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="226.985" cy="132.456" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M226.641,136.449c-0.141-0.015-0.243-0.143-0.225-0.284c0.018-0.142,0.148-0.244,0.29-0.229
						c0.143,0.015,0.243,0.142,0.225,0.284C226.913,136.362,226.783,136.464,226.641,136.449z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M226.168,140.169c-0.141-0.021-0.242-0.152-0.224-0.294c0.018-0.141,0.148-0.239,0.289-0.219
							c0.142,0.02,0.242,0.151,0.224,0.293S226.31,140.189,226.168,140.169z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="225.584" cy="143.61" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="224.849" cy="147.286" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M223.925,151.186c-0.138-0.036-0.219-0.177-0.18-0.314s0.183-0.219,0.319-0.184
						c0.141,0.036,0.221,0.176,0.182,0.314C224.207,151.14,224.063,151.222,223.925,151.186z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M222.9,154.793c-0.136-0.041-0.216-0.186-0.177-0.323c0.039-0.137,0.182-0.215,0.318-0.174
						c0.137,0.041,0.217,0.185,0.178,0.323C223.18,154.756,223.038,154.834,222.9,154.793z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.82" cy="158.112" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.552" cy="161.639" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M219.061,165.36c-0.131-0.056-0.19-0.208-0.132-0.337c0.06-0.129,0.212-0.19,0.342-0.136
						c0.133,0.056,0.195,0.208,0.135,0.338C219.347,165.356,219.192,165.416,219.061,165.36z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M217.516,168.776c-0.128-0.061-0.187-0.216-0.128-0.346c0.059-0.129,0.212-0.186,0.341-0.125
						c0.13,0.061,0.187,0.215,0.128,0.346S217.646,168.838,217.516,168.776z" />
                </g>
                <g>
                  <a id="p24" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="215.958" cy="171.9" r="0.258" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.183" cy="175.202" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M212.159,178.661c-0.121-0.075-0.158-0.233-0.081-0.353c0.077-0.12,0.239-0.156,0.36-0.083
						c0.123,0.074,0.159,0.232,0.082,0.353C212.442,178.699,212.281,178.736,212.159,178.661z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M210.135,181.818c-0.118-0.079-0.153-0.241-0.076-0.361c0.077-0.12,0.236-0.153,0.355-0.075
						c0.119,0.079,0.153,0.24,0.076,0.361C210.413,181.864,210.254,181.897,210.135,181.818z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.134" cy="184.678" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.892" cy="187.682" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M203.383,190.807c-0.109-0.092-0.123-0.254-0.029-0.362c0.093-0.107,0.259-0.12,0.368-0.029
						c0.11,0.091,0.123,0.253,0.029,0.361C203.657,190.886,203.492,190.899,203.383,190.807z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M200.928,193.641c-0.105-0.095-0.116-0.261-0.023-0.369c0.093-0.107,0.256-0.117,0.361-0.022
						c0.107,0.095,0.117,0.26,0.023,0.368C201.196,193.727,201.034,193.737,200.928,193.641z" />
                </g>
                <g>
                  <a id="p25" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="198.528" cy="196.176" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.87" cy="198.82" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M192.931,201.543c-0.095-0.106-0.084-0.269,0.023-0.362s0.273-0.082,0.368,0.024
						c0.096,0.106,0.085,0.269-0.023,0.362C193.19,201.661,193.025,201.651,192.931,201.543z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M190.095,203.997c-0.089-0.108-0.075-0.273,0.032-0.367c0.107-0.093,0.27-0.079,0.361,0.03
						c0.091,0.11,0.078,0.275-0.03,0.369C190.349,204.123,190.187,204.109,190.095,203.997z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="187.35" cy="206.154" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="184.333" cy="208.379" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.244" cy="210.504" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M177.862,212.654c-0.073-0.122-0.034-0.279,0.09-0.351c0.123-0.072,0.279-0.038,0.354,0.084
						c0.074,0.121,0.039,0.286-0.085,0.358C178.096,212.818,177.936,212.776,177.862,212.654z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M174.611,214.523c-0.069-0.125-0.025-0.284,0.099-0.355c0.123-0.071,0.28-0.026,0.35,0.098
						c0.07,0.125,0.026,0.283-0.098,0.354C174.837,214.692,174.68,214.648,174.611,214.523z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.524" cy="216.152" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="168.156" cy="217.798" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M164.494,219.427c-0.055-0.131,0.008-0.281,0.141-0.333c0.132-0.052,0.285,0.013,0.34,0.142
						c0.057,0.133-0.006,0.283-0.14,0.335C164.702,219.624,164.549,219.56,164.494,219.427z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M161.006,220.803c-0.05-0.133,0.017-0.285,0.149-0.337c0.132-0.052,0.281,0.015,0.332,0.147
						c0.051,0.133-0.016,0.284-0.149,0.337C161.205,221.002,161.056,220.937,161.006,220.803z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.711" cy="221.956" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="154.138" cy="223.088" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M150.276,224.161c-0.035-0.138,0.049-0.277,0.188-0.309c0.139-0.031,0.28,0.054,0.315,0.19
						c0.036,0.14-0.047,0.281-0.187,0.312C150.453,224.386,150.311,224.299,150.276,224.161z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.623,225.004c-0.03-0.139,0.059-0.279,0.197-0.311c0.139-0.032,0.276,0.056,0.307,0.195
						c0.03,0.14-0.058,0.279-0.197,0.311C146.79,225.231,146.652,225.144,146.623,225.004z" />
                </g>
                <g>
                  <a id="p26" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="143.194" cy="225.66" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.493" cy="226.255" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M135.516,226.754c-0.014-0.143,0.09-0.263,0.231-0.283c0.141-0.018,0.268,0.083,0.283,0.225
						c0.015,0.143-0.088,0.272-0.23,0.29C135.658,227.006,135.53,226.895,135.516,226.754z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.035" cy="227.019" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.29" cy="227.192" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M124.283,227.246c0.001-0.142,0.118-0.256,0.26-0.252c0.142,0.003,0.257,0.123,0.257,0.265
						c0,0.143-0.117,0.256-0.26,0.252C124.397,227.508,124.282,227.39,124.283,227.246z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M120.535,227.151c0.006-0.142,0.127-0.255,0.27-0.252c0.143,0.004,0.253,0.123,0.248,0.265
						c-0.006,0.143-0.126,0.256-0.27,0.252C120.639,227.413,120.528,227.294,120.535,227.151z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.052" cy="226.916" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.321" cy="226.557" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M109.347,226.035c0.022-0.14,0.154-0.235,0.294-0.21c0.14,0.026,0.238,0.15,0.217,0.292
						c-0.021,0.14-0.154,0.245-0.295,0.219C109.421,226.311,109.325,226.176,109.347,226.035z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.655,225.375c0.027-0.139,0.164-0.234,0.304-0.208c0.14,0.025,0.232,0.16,0.206,0.299
						c-0.026,0.141-0.162,0.234-0.303,0.209C105.72,225.65,105.628,225.516,105.655,225.375z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.244" cy="224.64" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M98.355,223.67c0.038-0.137,0.179-0.218,0.316-0.179c0.137,0.039,0.218,0.184,0.181,0.321
						c-0.037,0.138-0.178,0.218-0.316,0.179C98.399,223.952,98.318,223.809,98.355,223.67z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M94.75,222.64c0.043-0.137,0.186-0.207,0.324-0.17c0.137,0.039,0.214,0.182,0.172,0.318
						c-0.042,0.137-0.188,0.216-0.325,0.176C94.782,222.928,94.708,222.775,94.75,222.64z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.443" cy="221.521" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.931" cy="220.21" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M84.229,218.676c0.057-0.13,0.209-0.189,0.339-0.13c0.129,0.059,0.188,0.213,0.132,0.344
						c-0.057,0.131-0.208,0.189-0.339,0.131C84.231,218.961,84.171,218.808,84.229,218.676z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M80.816,217.124c0.063-0.129,0.214-0.177,0.344-0.119c0.129,0.059,0.185,0.212,0.124,0.34
						c-0.062,0.129-0.217,0.186-0.348,0.127C80.805,217.413,80.754,217.251,80.816,217.124z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.709" cy="215.532" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.429" cy="213.717" r="0.259" />
                </g>
                <g>
                  <a id="p27" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="71.21" cy="211.794" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M67.847,209.616c0.08-0.118,0.24-0.147,0.356-0.064c0.114,0.084,0.148,0.241,0.069,0.359
							s-0.244,0.155-0.359,0.07C67.795,209.897,67.766,209.734,67.847,209.616z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M64.791,207.442c0.084-0.114,0.248-0.141,0.364-0.059s0.142,0.244,0.058,0.358
						c-0.084,0.116-0.247,0.142-0.364,0.059S64.706,207.558,64.791,207.442z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="62.024" cy="205.315" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="59.123" cy="202.94" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M56.115,200.292c0.096-0.104,0.259-0.111,0.362-0.012c0.103,0.098,0.108,0.264,0.012,0.369
						c-0.096,0.105-0.258,0.111-0.362,0.012S56.018,200.397,56.115,200.292z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M53.401,197.704c0.1-0.101,0.266-0.104,0.369-0.005c0.103,0.098,0.105,0.261,0.005,0.362
						c-0.1,0.103-0.265,0.105-0.369,0.006C53.303,197.968,53.3,197.806,53.401,197.704z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="50.976" cy="195.193" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="48.455" cy="192.419" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M45.867,189.359c0.11-0.089,0.272-0.072,0.361,0.04c0.088,0.112,0.069,0.277-0.041,0.367
						c-0.111,0.091-0.272,0.073-0.361-0.04C45.737,189.614,45.755,189.449,45.867,189.359z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M43.55,186.411c0.114-0.085,0.278-0.064,0.367,0.048c0.088,0.112,0.066,0.273-0.047,0.359
						c-0.114,0.086-0.278,0.065-0.367-0.047C43.415,186.658,43.436,186.497,43.55,186.411z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.52" cy="183.571" r="0.259" />
                </g>
                <g>
                  <a id="p28" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="39.433" cy="180.457" r="0.258" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M37.322,177.051c0.122-0.072,0.28-0.031,0.351,0.092c0.071,0.123,0.028,0.283-0.093,0.356
						c-0.123,0.073-0.28,0.032-0.352-0.092S37.198,177.124,37.322,177.051z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M35.456,173.798c0.124-0.067,0.28-0.021,0.353,0.102c0.071,0.124,0.026,0.28-0.098,0.348
						c-0.125,0.069-0.285,0.024-0.356-0.1C35.281,174.025,35.329,173.867,35.456,173.798z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.864" cy="170.691" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.259" cy="167.303" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.767" cy="163.865" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M29.305,160.132c0.135-0.049,0.277,0.022,0.329,0.155s-0.015,0.28-0.148,0.33
						c-0.134,0.05-0.285-0.018-0.337-0.151C29.096,160.332,29.172,160.181,29.305,160.132z" />
                </g>
                <g>
                  <a id="p29" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="28.186" cy="156.825" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="27.099" cy="153.238" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="26.129" cy="149.617" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M25.232,145.71c0.138-0.028,0.274,0.062,0.308,0.201c0.032,0.139-0.056,0.275-0.195,0.304
						c-0.14,0.029-0.279-0.06-0.312-0.2C24.999,145.876,25.091,145.739,25.232,145.71z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="24.618" cy="142.273" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="24.069" cy="138.565" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M23.618,134.583c0.142-0.013,0.267,0.092,0.277,0.234s-0.097,0.269-0.238,0.282
						c-0.142,0.014-0.266-0.091-0.277-0.234C23.369,134.722,23.476,134.596,23.618,134.583z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M23.334,130.844c0.142-0.008,0.267,0.101,0.278,0.243s-0.097,0.264-0.239,0.272
						c-0.143,0.009-0.267-0.1-0.278-0.243C23.084,130.974,23.191,130.852,23.334,130.844z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="23.227" cy="127.355" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="23.214" cy="123.607" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M23.334,119.601c0.142,0.008,0.25,0.129,0.239,0.271c-0.014,0.142-0.127,0.251-0.271,0.245
						c-0.141-0.007-0.26-0.129-0.246-0.271C23.066,119.702,23.191,119.593,23.334,119.601z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="23.599" cy="116.119" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="24.004" cy="112.393" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M24.573,108.426c0.14,0.023,0.235,0.156,0.21,0.296c-0.025,0.14-0.16,0.235-0.301,0.213
						c-0.141-0.022-0.235-0.155-0.21-0.296C24.297,108.498,24.432,108.402,24.573,108.426z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M25.232,104.734c0.14,0.029,0.233,0.166,0.208,0.306c-0.025,0.14-0.16,0.231-0.299,0.203
						c-0.14-0.028-0.233-0.165-0.208-0.305C24.958,104.797,25.092,104.706,25.232,104.734z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="26.013" cy="101.332" r="0.259" />
                </g>
                <g>
                  <a id="p30" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="26.958" cy="97.704" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M28.104,93.864c0.135,0.043,0.209,0.189,0.164,0.324c-0.046,0.135-0.194,0.209-0.33,0.166
							c-0.136-0.043-0.209-0.188-0.164-0.324S27.967,93.82,28.104,93.864z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M29.305,90.312c0.133,0.048,0.206,0.198,0.161,0.333c-0.046,0.134-0.192,0.205-0.326,0.157
						c-0.135-0.048-0.207-0.197-0.161-0.333S29.171,90.263,29.305,90.312z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.577" cy="87.061" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.046" cy="83.613" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M33.747,79.984c0.127,0.063,0.179,0.218,0.114,0.344c-0.065,0.127-0.222,0.178-0.35,0.116
						c-0.128-0.062-0.18-0.217-0.114-0.344S33.618,79.92,33.747,79.984z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M35.456,76.646c0.125,0.068,0.175,0.227,0.11,0.353c-0.065,0.127-0.221,0.175-0.346,0.107
						c-0.126-0.067-0.175-0.226-0.11-0.353C35.175,76.626,35.33,76.578,35.456,76.646z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.193" cy="73.618" r="0.259" />
                </g>
                <g>
                  <a id="p31" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="39.154" cy="70.423" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.221" cy="67.295" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M43.55,64.034c0.115,0.086,0.132,0.244,0.051,0.361c-0.083,0.116-0.243,0.14-0.357,0.056
						c-0.115-0.085-0.141-0.249-0.058-0.365C43.268,63.967,43.438,63.949,43.55,64.034z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="45.707" cy="61.288" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="48.116" cy="58.417" r="0.258" />
                </g>
                <g>
                  <a id="p32" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="50.619" cy="55.626" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M53.401,52.74c0.1,0.101,0.099,0.264-0.004,0.362c-0.104,0.097-0.269,0.096-0.368-0.003
							c-0.103-0.103-0.102-0.265,0.003-0.364C53.135,52.637,53.301,52.639,53.401,52.74z"/>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M56.115,50.153c0.096,0.104,0.092,0.27-0.011,0.369c-0.103,0.098-0.266,0.092-0.363-0.012
						c-0.098-0.104-0.092-0.27,0.011-0.368C55.856,50.042,56.019,50.048,56.115,50.153z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="58.732" cy="47.843" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="61.617" cy="45.449" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M64.791,43.002c0.084,0.115,0.059,0.275-0.057,0.358c-0.116,0.082-0.279,0.056-0.364-0.059
						c-0.085-0.115-0.06-0.275,0.057-0.357C64.543,42.861,64.706,42.887,64.791,43.002z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M67.847,40.829c0.08,0.118,0.052,0.281-0.064,0.363c-0.116,0.083-0.276,0.054-0.357-0.063
							c-0.081-0.118-0.052-0.28,0.064-0.363S67.767,40.711,67.847,40.829z" />
                </g>
                <g>
                  <a id="p33" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="70.775" cy="38.929" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.98" cy="36.985" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M77.479,35.03c0.066,0.125,0.018,0.281-0.108,0.346c-0.127,0.065-0.285,0.015-0.352-0.11
						C76.951,35.14,77,34.985,77.127,34.92S77.412,34.904,77.479,35.03z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M80.816,33.321c0.062,0.127,0.01,0.285-0.117,0.351c-0.127,0.064-0.281,0.013-0.344-0.115
						c-0.063-0.128-0.011-0.285,0.117-0.351C80.6,33.141,80.754,33.192,80.816,33.321z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.994" cy="31.875" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.45" cy="30.425" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M91.199,29.007c0.047,0.134-0.023,0.281-0.158,0.326c-0.135,0.046-0.284-0.027-0.332-0.161
						c-0.048-0.135,0.022-0.281,0.158-0.327S91.151,28.872,91.199,29.007z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M94.75,27.805c0.042,0.136-0.032,0.284-0.167,0.33c-0.135,0.046-0.28-0.028-0.323-0.164
						c-0.043-0.137,0.031-0.284,0.167-0.33S94.708,27.668,94.75,27.805z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.107" cy="26.845" r="0.259" />
                </g>
                <g>
                  <a id="p34" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="101.74" cy="25.921" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.656,25.069c0.027,0.14-0.064,0.274-0.205,0.299s-0.277-0.069-0.305-0.208
						c-0.028-0.14,0.064-0.274,0.205-0.299C105.492,24.835,105.628,24.929,105.656,25.069z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M109.347,24.41c0.022,0.14-0.074,0.276-0.214,0.301c-0.14,0.025-0.272-0.07-0.295-0.21
						c-0.023-0.141,0.073-0.276,0.214-0.301C109.193,24.175,109.325,24.269,109.347,24.41z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.808" cy="23.953" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.537" cy="23.569" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M120.535,23.293c0.006,0.142-0.104,0.261-0.247,0.265c-0.142,0.003-0.264-0.11-0.271-0.252
						c-0.007-0.143,0.103-0.261,0.246-0.265C120.407,23.038,120.529,23.15,120.535,23.293z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M124.284,23.198c0.001,0.143-0.113,0.262-0.256,0.266c-0.142,0.003-0.259-0.11-0.261-0.252
						c-0.002-0.143,0.112-0.262,0.255-0.265C124.165,22.942,124.282,23.056,124.284,23.198z" />
                </g>
                <g>
                  <a id="p35" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="127.774" cy="23.24" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M131.777,23.407c-0.009,0.142-0.132,0.25-0.274,0.239c-0.142-0.011-0.25-0.136-0.242-0.278
							c0.008-0.143,0.131-0.25,0.273-0.239C131.678,23.14,131.787,23.264,131.777,23.407z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M135.516,23.691c-0.014,0.141-0.141,0.249-0.283,0.238s-0.246-0.136-0.233-0.277
						c0.014-0.142,0.14-0.249,0.283-0.238C135.426,23.425,135.531,23.549,135.516,23.691z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.98" cy="24.124" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="142.685" cy="24.694" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.623,25.441c-0.03,0.139-0.167,0.227-0.305,0.195c-0.139-0.032-0.228-0.172-0.199-0.311
						c0.029-0.14,0.166-0.227,0.305-0.195C146.563,25.162,146.653,25.301,146.623,25.441z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M150.276,26.284c-0.035,0.138-0.176,0.225-0.315,0.193c-0.139-0.032-0.223-0.171-0.189-0.309
						c0.034-0.139,0.175-0.225,0.314-0.193C150.227,26.007,150.312,26.146,150.276,26.284z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="153.64" cy="27.216" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.222" cy="28.323" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M161.006,29.642c-0.05,0.133-0.198,0.2-0.331,0.148s-0.2-0.204-0.15-0.337
						c0.049-0.134,0.197-0.2,0.331-0.148C160.989,29.357,161.056,29.508,161.006,29.642z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M164.495,31.018c-0.055,0.131-0.208,0.196-0.34,0.145c-0.132-0.052-0.195-0.203-0.142-0.334
						c0.055-0.132,0.207-0.197,0.34-0.145C164.486,30.736,164.55,30.886,164.495,31.018z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="167.685" cy="32.434" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.064" cy="34.058" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M174.611,35.922c-0.069,0.124-0.226,0.169-0.349,0.098c-0.124-0.071-0.167-0.231-0.099-0.355
						c0.068-0.125,0.225-0.169,0.349-0.098C174.636,35.638,174.681,35.797,174.611,35.922z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M177.862,37.791c-0.073,0.122-0.234,0.164-0.357,0.093c-0.123-0.071-0.164-0.229-0.091-0.351
						c0.073-0.123,0.233-0.165,0.357-0.093C177.895,37.512,177.936,37.668,177.862,37.791z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="180.809" cy="39.662" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="183.912" cy="41.766" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M187.146,44.131c-0.086,0.113-0.248,0.134-0.36,0.046c-0.112-0.088-0.132-0.252-0.047-0.366
						c0.086-0.114,0.248-0.135,0.36-0.046C187.212,43.854,187.233,44.018,187.146,44.131z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M190.095,46.448c-0.09,0.109-0.255,0.128-0.367,0.04s-0.129-0.25-0.039-0.36
						c0.09-0.111,0.254-0.128,0.367-0.04S190.186,46.338,190.095,46.448z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="192.735" cy="48.732" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.496" cy="51.268" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="198.171" cy="53.895" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M200.928,56.804c-0.105,0.095-0.268,0.086-0.361-0.021c-0.098-0.103-0.092-0.266,0.013-0.361
						c0.105-0.097,0.271-0.09,0.37,0.013C201.044,56.543,201.034,56.708,200.928,56.804z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.214" cy="59.443" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.573" cy="62.356" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.834" cy="65.346" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M210.135,68.627c-0.118,0.079-0.277,0.046-0.354-0.074c-0.077-0.12-0.042-0.282,0.076-0.362
						c0.118-0.08,0.277-0.047,0.354,0.074C210.288,68.386,210.254,68.548,210.135,68.627z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M212.159,71.784c-0.121,0.075-0.283,0.039-0.36-0.082c-0.077-0.12-0.04-0.279,0.081-0.354
						c0.122-0.076,0.282-0.039,0.359,0.082C212.317,71.551,212.281,71.709,212.159,71.784z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="213.925" cy="74.795" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.722" cy="78.085" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M217.516,81.668c-0.128,0.061-0.282,0.005-0.34-0.125c-0.059-0.129-0.001-0.285,0.127-0.347
						c0.129-0.062,0.281-0.006,0.34,0.125C217.702,81.452,217.646,81.607,217.516,81.668z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M219.061,85.085c-0.131,0.056-0.286-0.003-0.344-0.133c-0.059-0.129,0-0.281,0.131-0.338
						c0.131-0.057,0.285,0.002,0.344,0.133C219.251,84.878,219.192,85.029,219.061,85.085z" />
                </g>
                <g>
                  <a id="p36" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="220.362" cy="88.325" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.654" cy="91.844" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M222.9,95.652c-0.136,0.041-0.279-0.036-0.318-0.173c-0.039-0.137,0.042-0.282,0.177-0.324
						c0.137-0.042,0.279,0.036,0.318,0.173C223.117,95.466,223.038,95.611,222.9,95.652z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M223.925,99.259c-0.138,0.036-0.282-0.045-0.321-0.182c-0.039-0.137,0.042-0.278,0.18-0.315
						c0.138-0.037,0.281,0.045,0.32,0.183C224.144,99.082,224.063,99.223,223.925,99.259z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="224.733" cy="102.655" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="225.493" cy="106.326" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M226.168,110.276c-0.141,0.021-0.271-0.077-0.289-0.218c-0.018-0.141,0.083-0.273,0.223-0.294
						c0.142-0.022,0.271,0.076,0.289,0.218C226.409,110.124,226.31,110.256,226.168,110.276z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M226.641,113.997c-0.142,0.015-0.272-0.087-0.29-0.228c-0.018-0.141,0.083-0.269,0.225-0.285
						c0.142-0.017,0.272,0.085,0.29,0.228S226.783,113.981,226.641,113.997z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="226.945" cy="117.474" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="227.167" cy="121.216" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M227.268,125.223c-0.142,0-0.262-0.116-0.265-0.258c-0.004-0.143,0.11-0.258,0.252-0.259
						c0.143,0,0.262,0.115,0.266,0.258C227.524,125.106,227.411,125.223,227.268,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="221.571" cy="126.31" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.54" cy="127.553" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.508" cy="128.797" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.477" cy="130.04" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.39" cy="131.28" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.295" cy="132.52" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.201" cy="133.76" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.105" cy="135" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.954" cy="136.234" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.797" cy="137.467" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.641" cy="138.701" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M220.455,140.087c-0.084-0.013-0.141-0.092-0.126-0.177c0.014-0.084,0.09-0.143,0.175-0.13
						c0.084,0.013,0.146,0.093,0.132,0.177C220.621,140.042,220.54,140.101,220.455,140.087z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.263" cy="141.158" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.045" cy="142.383" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.826" cy="143.607" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.598" cy="144.829" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.319" cy="146.041" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.039" cy="147.252" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.76" cy="148.464" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.462" cy="149.671" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.122" cy="150.867" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.782" cy="152.063" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.442" cy="153.26" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.075" cy="154.447" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.676" cy="155.625" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.278" cy="156.802" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.879" cy="157.981" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.441" cy="159.144" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.985" cy="160.301" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.529" cy="161.458" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.073" cy="162.615" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="213.567" cy="163.75" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="213.055" cy="164.883" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.542" cy="166.017" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M211.952,167.285c-0.077-0.038-0.108-0.13-0.069-0.206s0.134-0.108,0.21-0.071
						c0.078,0.037,0.108,0.129,0.069,0.206C212.124,167.291,212.029,167.322,211.952,167.285z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.456" cy="168.253" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="210.889" cy="169.36" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="210.323" cy="170.467" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="209.736" cy="171.562" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="209.116" cy="172.641" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.496" cy="173.719" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.876" cy="174.797" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.224" cy="175.855" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.553" cy="176.902" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.882" cy="177.949" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.211" cy="178.995" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="204.496" cy="180.013" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.775" cy="181.026" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.054" cy="182.04" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="202.326" cy="183.047" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="201.558" cy="184.025" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="200.789" cy="185.003" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="200.021" cy="185.98" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="199.233" cy="186.941" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="198.418" cy="187.881" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="197.604" cy="188.821" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="196.79" cy="189.761" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.944" cy="190.672" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.086" cy="191.572" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="194.228" cy="192.472" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.257,193.479c-0.06-0.061-0.059-0.158,0.003-0.217c0.059-0.062,0.157-0.063,0.217-0.003
						c0.061,0.061,0.062,0.16,0.003,0.222C193.417,193.542,193.318,193.541,193.257,193.479z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="192.47" cy="194.23" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="191.57" cy="195.088" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.67" cy="195.947" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="189.759" cy="196.792" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="188.819" cy="197.606" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="187.879" cy="198.421" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="186.939" cy="199.235" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="185.978" cy="200.023" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="185" cy="200.792" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="184.022" cy="201.56" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="183.045" cy="202.328" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="182.037" cy="203.057" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.024" cy="203.777" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="180.01" cy="204.498" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M178.862,205.297c-0.047-0.07-0.028-0.166,0.044-0.212s0.17-0.026,0.217,0.044
						c0.049,0.072,0.029,0.167-0.043,0.213C179.008,205.389,178.91,205.368,178.862,205.297z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.947" cy="205.884" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.9" cy="206.556" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="175.853" cy="207.227" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="174.794" cy="207.879" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="173.716" cy="208.499" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.638" cy="209.118" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.561" cy="209.738" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.464" cy="210.325" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="169.357" cy="210.892" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="168.251" cy="211.458" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="167.144" cy="212.025" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.014" cy="212.544" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="164.881" cy="213.057" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.748" cy="213.569" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M162.468,214.132c-0.033-0.079,0.004-0.169,0.084-0.2c0.08-0.031,0.171,0.007,0.204,0.085
						c0.034,0.08-0.003,0.17-0.083,0.201C162.593,214.25,162.501,214.211,162.468,214.132z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="161.456" cy="214.531" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="160.299" cy="214.988" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.142" cy="215.444" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.978" cy="215.881" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="156.8" cy="216.28" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.622" cy="216.678" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="154.445" cy="217.077" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="153.257" cy="217.445" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.061" cy="217.784" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="150.865" cy="218.124" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M149.519,218.506c-0.021-0.083,0.028-0.169,0.11-0.193c0.082-0.023,0.167,0.025,0.188,0.108
						c0.022,0.083-0.027,0.169-0.11,0.192C149.625,218.638,149.541,218.589,149.519,218.506z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="148.461" cy="218.761" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="147.25" cy="219.041" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="146.038" cy="219.321" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M144.675,219.635c-0.018-0.083,0.036-0.167,0.12-0.187c0.083-0.019,0.166,0.034,0.183,0.117
						c0.018,0.084-0.036,0.168-0.12,0.187C144.774,219.772,144.692,219.72,144.675,219.635z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.604" cy="219.828" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="142.38" cy="220.047" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="141.156" cy="220.266" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.931" cy="220.481" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.698" cy="220.643" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.465" cy="220.799" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.231" cy="220.956" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M134.842,221.121c-0.008-0.085,0.054-0.16,0.14-0.166c0.085-0.005,0.161,0.053,0.17,0.139
						c0.009,0.084-0.053,0.165-0.139,0.17C134.927,221.27,134.851,221.206,134.842,221.121z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.758" cy="221.203" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.517" cy="221.297" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.278" cy="221.392" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.037" cy="221.479" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.794" cy="221.51" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.551" cy="221.542" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="126.308" cy="221.573" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M124.91,221.593c0-0.085,0.07-0.153,0.156-0.151c0.085,0.002,0.155,0.074,0.155,0.159
						c0,0.086-0.07,0.153-0.155,0.151C124.979,221.75,124.909,221.679,124.91,221.593z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.822" cy="221.565" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.579" cy="221.534" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.335" cy="221.502" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M119.938,221.451c0.004-0.085,0.078-0.15,0.163-0.144c0.085,0.007,0.151,0.082,0.147,0.167
						c-0.004,0.086-0.078,0.15-0.163,0.143C119.999,221.611,119.933,221.536,119.938,221.451z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.853" cy="221.368" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.613" cy="221.274" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.373" cy="221.18" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M114.98,221.054c0.009-0.084,0.085-0.146,0.17-0.135s0.147,0.089,0.138,0.174
						c-0.009,0.085-0.085,0.146-0.17,0.135C115.033,221.217,114.971,221.139,114.98,221.054z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.901" cy="220.917" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.667" cy="220.76" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.434" cy="220.603" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.203" cy="220.429" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.979" cy="220.211" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.754" cy="219.992" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="106.53" cy="219.773" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="105.311" cy="219.531" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.1" cy="219.251" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.888" cy="218.971" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="101.676" cy="218.691" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.473" cy="218.378" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="99.277" cy="218.039" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.081" cy="217.699" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M96.735,217.317c0.025-0.082,0.113-0.13,0.195-0.106c0.082,0.023,0.129,0.109,0.104,0.191
						c-0.025,0.083-0.113,0.129-0.195,0.106S96.709,217.399,96.735,217.317z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="95.701" cy="216.977" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.524" cy="216.578" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="93.346" cy="216.18" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="92.168" cy="215.78" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.01" cy="215.329" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="89.853" cy="214.873" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.696" cy="214.417" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M87.401,213.889c0.033-0.078,0.125-0.114,0.202-0.079c0.078,0.035,0.114,0.128,0.081,0.207
						s-0.124,0.114-0.202,0.079C87.403,214.061,87.367,213.968,87.401,213.889z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="86.409" cy="213.441" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="85.276" cy="212.928" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.143" cy="212.416" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.02" cy="211.883" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.913" cy="211.316" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="80.806" cy="210.75" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.7" cy="210.183" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="78.611" cy="209.583" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.533" cy="208.963" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.455" cy="208.343" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="75.377" cy="207.723" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.326" cy="207.059" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.279" cy="206.387" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.232" cy="205.716" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.19" cy="205.038" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="70.177" cy="204.318" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="69.163" cy="203.597" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.15" cy="202.876" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="67.151" cy="202.136" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="66.174" cy="201.367" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="65.196" cy="200.599" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="64.218" cy="199.831" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="63.267" cy="199.031" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="62.327" cy="198.217" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="61.387" cy="197.403" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="60.449" cy="196.586" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="59.546" cy="195.732" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="58.646" cy="194.874" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="57.746" cy="194.016" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="56.856" cy="193.147" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="55.998" cy="192.247" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="55.14" cy="191.347" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="54.282" cy="190.447" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="53.447" cy="189.526" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="52.633" cy="188.586" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.819" cy="187.646" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.005" cy="186.706" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="50.228" cy="185.735" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="49.459" cy="184.758" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="48.691" cy="183.78" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="47.927" cy="182.798" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="47.206" cy="181.786" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="46.486" cy="180.772" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="45.765" cy="179.759" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="45.062" cy="178.733" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="44.391" cy="177.687" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="43.72" cy="176.64" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="43.048" cy="175.593" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="42.409" cy="174.527" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.789" cy="173.449" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.17" cy="172.371" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="40.55" cy="171.293" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.977" cy="170.19" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.41" cy="169.083" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.843" cy="167.976" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.219,166.724c0.077-0.037,0.168-0.003,0.204,0.075c0.035,0.078,0,0.172-0.076,0.208
						c-0.077,0.038-0.168,0.004-0.204-0.074C38.107,166.854,38.141,166.761,38.219,166.724z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.771" cy="165.733" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.258" cy="164.6" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="36.746" cy="163.467" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="36.254" cy="162.325" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="35.798" cy="161.168" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="35.341" cy="160.011" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="34.885" cy="158.854" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="34.462" cy="157.686" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="34.063" cy="156.508" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.665" cy="155.33" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.267" cy="154.152" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.913" cy="152.96" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.574" cy="151.764" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.234" cy="150.568" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.856,149.221c0.084-0.021,0.164,0.03,0.188,0.112c0.023,0.083-0.025,0.167-0.108,0.188
						c-0.083,0.021-0.169-0.028-0.192-0.111C31.719,149.328,31.774,149.242,31.856,149.221z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.612" cy="148.16" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.332" cy="146.949" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.052" cy="145.737" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M30.751,144.371c0.083-0.017,0.164,0.038,0.179,0.123c0.015,0.084-0.041,0.167-0.123,0.183
						c-0.085,0.018-0.167-0.037-0.181-0.122C30.611,144.471,30.667,144.388,30.751,144.371z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.56" cy="143.3" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.341" cy="142.076" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.123" cy="140.852" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M29.898,139.472c0.084-0.013,0.162,0.046,0.173,0.131s-0.049,0.165-0.134,0.177
						c-0.085,0.013-0.162-0.046-0.173-0.131C29.753,139.563,29.813,139.484,29.898,139.472z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.761" cy="138.392" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.604" cy="137.158" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.448" cy="135.925" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M29.299,134.534c0.084-0.008,0.16,0.055,0.166,0.14c0.007,0.085-0.058,0.162-0.143,0.17
						c-0.085,0.009-0.16-0.054-0.167-0.14C29.149,134.619,29.213,134.542,29.299,134.534z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.216" cy="133.449" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.122" cy="132.209" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.028" cy="130.969" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.956" cy="129.729" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.925" cy="128.485" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.894" cy="127.242" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.862" cy="125.999" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.854" cy="124.756" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.885" cy="123.513" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.917" cy="122.27" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="28.948" cy="121.027" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.004" cy="119.785" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.098" cy="118.545" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.193" cy="117.305" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.287" cy="116.065" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.409" cy="114.828" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.565" cy="113.594" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.722" cy="112.361" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="29.879" cy="111.127" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.068" cy="109.899" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.287" cy="108.675" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.505" cy="107.45" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.724" cy="106.226" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="30.982" cy="105.01" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.262" cy="103.799" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.542" cy="102.587" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.856,101.224c0.083,0.021,0.135,0.106,0.115,0.19c-0.019,0.083-0.103,0.134-0.185,0.113
						c-0.083-0.021-0.135-0.106-0.116-0.19S31.773,101.203,31.856,101.224z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.149" cy="100.176" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.489" cy="98.98" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.829" cy="97.783" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.171" cy="96.588" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.565" cy="95.409" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.964" cy="94.231" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="34.363" cy="93.053" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M34.829,91.734c0.08,0.03,0.12,0.119,0.088,0.198c-0.031,0.08-0.123,0.121-0.203,0.091
						c-0.081-0.029-0.12-0.118-0.088-0.198C34.657,91.745,34.748,91.705,34.829,91.734z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="35.228" cy="90.722" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="35.684" cy="89.565" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="36.14" cy="88.409" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="36.618" cy="87.261" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.13" cy="86.128" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.643" cy="84.995" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.155" cy="83.862" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.701" cy="82.745" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.268" cy="81.638" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.835" cy="80.532" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M40.473,79.287c0.075,0.041,0.105,0.136,0.066,0.212c-0.039,0.076-0.132,0.104-0.208,0.064
						c-0.076-0.041-0.105-0.136-0.066-0.212C40.305,79.274,40.397,79.246,40.473,79.287z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.015" cy="78.343" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.635" cy="77.265" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="42.255" cy="76.187" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="42.881" cy="75.113" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="43.552" cy="74.066" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="44.223" cy="73.02" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="44.894" cy="71.973" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="45.585" cy="70.939" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="46.305" cy="69.926" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="47.026" cy="68.912" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="47.747" cy="67.899" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="48.499" cy="66.909" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="49.267" cy="65.931" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="50.036" cy="64.953" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="50.806" cy="63.977" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.615" cy="63.033" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="52.43" cy="62.094" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="53.244" cy="61.154" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="54.068" cy="60.223" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="54.926" cy="59.323" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="55.784" cy="58.423" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="56.642" cy="57.523" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="57.521" cy="56.644" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="58.421" cy="55.785" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="59.321" cy="54.927" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="60.221" cy="54.069" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="61.152" cy="53.245" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="62.092" cy="52.431" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="63.032" cy="51.617" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="63.975" cy="50.807" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="64.952" cy="50.038" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="65.93" cy="49.269" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="66.907" cy="48.501" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="67.897" cy="47.749" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.91" cy="47.028" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="69.924" cy="46.307" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="70.937" cy="45.587" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.971" cy="44.896" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.018" cy="44.225" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.065" cy="43.554" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M75.242,42.799c0.044,0.073,0.022,0.17-0.05,0.216c-0.072,0.046-0.167,0.024-0.211-0.048
						c-0.045-0.073-0.022-0.17,0.05-0.217S75.198,42.726,75.242,42.799z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.186" cy="42.256" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.264" cy="41.636" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="78.342" cy="41.017" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M79.562,40.333c0.041,0.075,0.012,0.168-0.064,0.207s-0.171,0.009-0.212-0.065
						c-0.041-0.076-0.012-0.168,0.064-0.208C79.426,40.228,79.521,40.257,79.562,40.333z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="80.53" cy="39.837" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.637" cy="39.27" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="82.744" cy="38.703" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.86" cy="38.157" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.993" cy="37.644" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="86.126" cy="37.132" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.26" cy="36.62" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.407" cy="36.142" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="89.564" cy="35.686" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="90.721" cy="35.229" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.877" cy="34.773" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="93.052" cy="34.364" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.23" cy="33.966" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="95.407" cy="33.567" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M96.735,33.127c0.025,0.082-0.021,0.167-0.104,0.191c-0.082,0.025-0.168-0.018-0.194-0.1
						c-0.025-0.082,0.02-0.172,0.102-0.197C96.623,32.998,96.71,33.045,96.735,33.127z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="97.782" cy="32.831" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.978" cy="32.491" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.174" cy="32.151" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M101.525,31.788c0.021,0.083-0.029,0.167-0.113,0.186c-0.083,0.02-0.168-0.033-0.19-0.115
						c-0.021-0.083,0.029-0.167,0.113-0.186C101.419,31.653,101.504,31.705,101.525,31.788z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.585" cy="31.544" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="103.797" cy="31.264" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="105.009" cy="30.984" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="106.225" cy="30.726" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.449" cy="30.507" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.673" cy="30.289" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="109.897" cy="30.07" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.126" cy="29.881" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.359" cy="29.724" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.593" cy="29.567" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="114.826" cy="29.41" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.064" cy="29.289" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.303" cy="29.194" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.543" cy="29.1" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.783" cy="29.006" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.025" cy="28.95" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.268" cy="28.919" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.511" cy="28.888" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="124.755" cy="28.856" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.998" cy="28.864" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.241" cy="28.896" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.484" cy="28.927" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129.727" cy="28.958" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.968" cy="29.03" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.208" cy="29.124" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.448" cy="29.218" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.688" cy="29.312" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.923" cy="29.45" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.157" cy="29.606" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.391" cy="29.763" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.624" cy="29.92" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.85" cy="30.125" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="142.074" cy="30.343" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.299" cy="30.562" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="144.522" cy="30.781" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.736" cy="31.054" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="146.947" cy="31.334" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="148.159" cy="31.614" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M149.52,31.939c-0.021,0.083-0.106,0.131-0.188,0.108c-0.082-0.025-0.133-0.105-0.112-0.188
						c0.021-0.082,0.107-0.138,0.189-0.112C149.491,31.77,149.541,31.855,149.52,31.939z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="150.566" cy="32.236" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="151.762" cy="32.576" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.958" cy="32.916" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M154.298,33.318c-0.026,0.081-0.113,0.126-0.194,0.098c-0.081-0.027-0.126-0.116-0.101-0.198
						c0.025-0.082,0.112-0.126,0.193-0.099C154.278,33.148,154.324,33.236,154.298,33.318z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.328" cy="33.667" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="156.506" cy="34.065" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.684" cy="34.464" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="158.853" cy="34.887" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="160.01" cy="35.344" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="161.167" cy="35.8" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.323" cy="36.256" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.465" cy="36.748" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="164.598" cy="37.26" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="165.731" cy="37.772" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M167.006,38.349c-0.037,0.077-0.131,0.111-0.209,0.076c-0.078-0.035-0.111-0.127-0.074-0.204
						c0.037-0.077,0.13-0.111,0.209-0.076C167.01,38.18,167.043,38.271,167.006,38.349z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="167.974" cy="38.845" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="169.081" cy="39.412" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.188" cy="39.979" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M171.426,40.629c-0.041,0.075-0.135,0.102-0.209,0.059c-0.074-0.043-0.101-0.139-0.061-0.214
						c0.042-0.076,0.135-0.102,0.209-0.059C171.44,40.458,171.467,40.554,171.426,40.629z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.369" cy="41.172" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="173.447" cy="41.792" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="174.525" cy="42.411" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="175.591" cy="43.051" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.638" cy="43.722" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.685" cy="44.393" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="178.732" cy="45.064" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.758" cy="45.767" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="180.771" cy="46.488" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.784" cy="47.208" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="182.797" cy="47.93" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="183.778" cy="48.693" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="184.756" cy="49.461" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="185.734" cy="50.23" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="186.704" cy="51.007" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="187.644" cy="51.821" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="188.584" cy="52.635" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="189.524" cy="53.449" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.445" cy="54.284" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="191.345" cy="55.142" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="192.245" cy="56" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="193.145" cy="56.858" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="194.014" cy="57.748" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="194.872" cy="58.648" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.73" cy="59.548" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="196.585" cy="60.451" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="197.401" cy="61.389" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="198.215" cy="62.329" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="199.029" cy="63.269" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="199.829" cy="64.22" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="200.597" cy="65.198" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="201.366" cy="66.176" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="202.134" cy="67.154" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="202.875" cy="68.153" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.595" cy="69.166" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="204.316" cy="70.179" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.036" cy="71.193" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.715" cy="72.235" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.386" cy="73.281" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.057" cy="74.328" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="207.722" cy="75.379" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.342" cy="76.457" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.961" cy="77.535" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="209.581" cy="78.613" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="210.181" cy="79.702" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="210.748" cy="80.809" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.315" cy="81.916" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.882" cy="83.022" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.414" cy="84.146" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.927" cy="85.279" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="213.439" cy="86.412" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M214.015,87.687c-0.079,0.033-0.172-0.003-0.207-0.081s0-0.169,0.079-0.202
						c0.079-0.034,0.171,0.002,0.207,0.081C214.129,87.562,214.094,87.653,214.015,87.687z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.415" cy="88.698" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.871" cy="89.855" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.328" cy="91.012" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M215.829,92.317c-0.08,0.029-0.168-0.013-0.195-0.094c-0.026-0.082,0.013-0.17,0.093-0.2
						c0.081-0.029,0.172,0.011,0.199,0.093C215.953,92.198,215.91,92.288,215.829,92.317z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.178" cy="93.348" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.577" cy="94.526" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.975" cy="95.704" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.358" cy="96.887" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="217.697" cy="98.083" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.037" cy="99.279" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.377" cy="100.476" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.69" cy="101.679" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.969" cy="102.89" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.249" cy="104.102" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.529" cy="105.313" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.771" cy="106.533" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="219.99" cy="107.757" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.209" cy="108.981" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.427" cy="110.205" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.601" cy="111.436" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.758" cy="112.67" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="220.915" cy="113.903" r="0.156" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M221.091,115.291c-0.085,0.009-0.164-0.053-0.174-0.138s0.05-0.161,0.135-0.17
						c0.085-0.009,0.163,0.053,0.174,0.138S221.177,115.282,221.091,115.291z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.178" cy="116.375" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.272" cy="117.615" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.366" cy="118.855" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.46" cy="120.095" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.5" cy="121.338" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.532" cy="122.581" r="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="221.563" cy="123.824" r="0.155" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M221.599,125.223c-0.085,0-0.157-0.069-0.159-0.155c-0.002-0.085,0.066-0.155,0.151-0.155
						c0.086,0,0.157,0.069,0.159,0.155C221.752,125.153,221.685,125.223,221.599,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="218.676" cy="128.707" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.459" cy="132.444" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.105" cy="136.169" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M217.567,140.134c-0.14-0.022-0.235-0.154-0.21-0.294c0.025-0.14,0.161-0.235,0.301-0.214
						c0.141,0.022,0.235,0.154,0.21,0.294C217.843,140.061,217.708,140.157,217.567,140.134z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M216.909,143.82c-0.139-0.028-0.233-0.165-0.208-0.305c0.025-0.14,0.16-0.231,0.299-0.204
						c0.141,0.027,0.233,0.164,0.208,0.305C217.183,143.757,217.049,143.848,216.909,143.82z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="216.138" cy="147.219" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.184" cy="150.838" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="214.092" cy="154.418" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M212.77,158.194c-0.133-0.05-0.2-0.199-0.148-0.331c0.052-0.132,0.204-0.199,0.337-0.149
						c0.134,0.05,0.2,0.198,0.147,0.331C213.054,158.177,212.903,158.244,212.77,158.194z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M211.394,161.676c-0.129-0.055-0.193-0.207-0.142-0.34c0.052-0.132,0.202-0.194,0.333-0.14
						c0.132,0.055,0.196,0.208,0.144,0.34C211.678,161.67,211.527,161.732,211.394,161.676z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="209.958" cy="164.852" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.296" cy="168.206" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.507" cy="171.493" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="204.593" cy="174.709" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M202.406,178.061c-0.117-0.081-0.146-0.24-0.064-0.356c0.083-0.116,0.246-0.144,0.363-0.064
						c0.119,0.08,0.147,0.24,0.064,0.356C202.687,178.112,202.524,178.142,202.406,178.061z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M200.237,181.111c-0.114-0.085-0.141-0.249-0.058-0.364s0.243-0.141,0.357-0.056
						c0.115,0.084,0.141,0.248,0.058,0.364C200.511,181.172,200.352,181.197,200.237,181.111z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="198.087" cy="183.855" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.677" cy="186.719" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="193.158" cy="189.488" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.536" cy="192.158" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M187.619,194.896c-0.095-0.105-0.085-0.268,0.022-0.361c0.107-0.093,0.273-0.082,0.369,0.023
						c0.096,0.106,0.086,0.268-0.022,0.361C187.879,195.014,187.714,195.003,187.619,194.896z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M184.789,197.348c-0.09-0.11-0.078-0.275,0.03-0.368c0.107-0.093,0.27-0.079,0.36,0.03
						c0.092,0.11,0.079,0.274-0.029,0.368C185.042,197.472,184.88,197.458,184.789,197.348z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M181.848,199.665c-0.086-0.113-0.066-0.277,0.046-0.365c0.111-0.087,0.273-0.066,0.36,0.046
						c0.087,0.113,0.067,0.277-0.045,0.365C182.096,199.799,181.935,199.778,181.848,199.665z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.032" cy="201.717" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="175.93" cy="203.811" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.75" cy="205.785" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M169.266,207.754c-0.067-0.125-0.019-0.281,0.107-0.346c0.126-0.064,0.285-0.015,0.353,0.11
						c0.068,0.125,0.02,0.28-0.107,0.345C169.491,207.929,169.333,207.88,169.266,207.754z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M165.934,209.46c-0.062-0.128-0.01-0.286,0.117-0.35c0.126-0.065,0.28-0.013,0.343,0.115
							c0.063,0.128,0.011,0.285-0.116,0.35C166.15,209.641,165.997,209.589,165.934,209.46z" />
                </g>
                <g>
                  <a id="p37" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="162.762" cy="210.904" r="0.258" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.303" cy="212.333" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.791" cy="213.629" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M151.984,214.86c-0.041-0.136,0.037-0.28,0.174-0.318c0.137-0.039,0.281,0.041,0.323,0.177
						c0.042,0.137-0.036,0.279-0.173,0.318C152.17,215.076,152.025,214.997,151.984,214.86z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M148.38,215.872c-0.035-0.139,0.049-0.275,0.187-0.311c0.136-0.039,0.277,0.043,0.312,0.18
						c0.037,0.139-0.046,0.282-0.184,0.321C148.557,216.098,148.415,216.009,148.38,215.872z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M144.732,216.713c-0.03-0.139,0.059-0.279,0.197-0.311c0.138-0.032,0.275,0.056,0.306,0.195
						c0.031,0.14-0.058,0.279-0.197,0.311C144.899,216.94,144.762,216.854,144.732,216.713z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="141.308" cy="217.359" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.608" cy="217.925" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M133.632,218.371c-0.012-0.142,0.092-0.267,0.234-0.277s0.268,0.097,0.281,0.238
						c0.014,0.143-0.091,0.267-0.234,0.277S133.645,218.513,133.632,218.371z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M129.898,218.648c-0.007-0.143,0.103-0.258,0.245-0.271c0.142-0.011,0.263,0.097,0.271,0.239
						c0.008,0.143-0.102,0.267-0.244,0.278C130.027,218.907,129.906,218.79,129.898,218.648z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="126.414" cy="218.736" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.672" cy="218.702" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.933" cy="218.532" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M114.946,218.193c0.016-0.141,0.143-0.243,0.284-0.225c0.141,0.018,0.243,0.148,0.229,0.29
						c-0.015,0.143-0.142,0.243-0.283,0.225C115.033,218.465,114.93,218.335,114.946,218.193z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M111.232,217.721c0.021-0.14,0.153-0.241,0.294-0.223s0.239,0.148,0.218,0.289
						c-0.02,0.142-0.152,0.241-0.293,0.224C111.309,217.991,111.211,217.862,111.232,217.721z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.799" cy="217.121" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.136" cy="216.351" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.506" cy="215.441" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M96.667,214.312c0.043-0.135,0.188-0.209,0.323-0.164c0.134,0.045,0.209,0.194,0.167,0.33
						c-0.043,0.136-0.188,0.209-0.323,0.164C96.698,214.595,96.624,214.448,96.667,214.312z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M93.121,213.111c0.049-0.133,0.198-0.206,0.333-0.16c0.134,0.045,0.204,0.192,0.156,0.326
						c-0.048,0.135-0.197,0.207-0.333,0.161C93.142,213.392,93.072,213.246,93.121,213.111z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="89.877" cy="211.836" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="86.444" cy="210.346" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.071" cy="208.725" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.761" cy="206.975" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M76.305,204.96c0.074-0.121,0.232-0.158,0.352-0.081s0.156,0.239,0.083,0.359
						c-0.075,0.124-0.232,0.16-0.353,0.083C76.267,205.243,76.229,205.082,76.305,204.96z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M73.153,202.939c0.079-0.118,0.241-0.152,0.361-0.076s0.152,0.236,0.074,0.354
						c-0.079,0.12-0.241,0.153-0.361,0.076C73.106,203.217,73.073,203.059,73.153,202.939z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="70.305" cy="200.931" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="67.324" cy="198.667" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="64.432" cy="196.291" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M61.446,193.628c0.097-0.104,0.26-0.109,0.363-0.012c0.103,0.098,0.107,0.264,0.011,0.368
						c-0.097,0.105-0.259,0.11-0.363,0.012C61.354,193.897,61.349,193.732,61.446,193.628z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M58.752,191.029c0.101-0.1,0.264-0.099,0.362,0.004c0.097,0.103,0.095,0.269-0.004,0.368
						c-0.102,0.103-0.265,0.102-0.363-0.002C58.648,191.295,58.651,191.129,58.752,191.029z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M56.169,188.319c0.105-0.096,0.271-0.091,0.369,0.012c0.098,0.103,0.092,0.265-0.012,0.362
						c-0.105,0.097-0.27,0.092-0.369-0.012S56.063,188.416,56.169,188.319z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="53.877" cy="185.693" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.517" cy="182.789" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="49.269" cy="179.796" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M46.998,176.502c0.119-0.078,0.279-0.045,0.355,0.075s0.042,0.281-0.077,0.36
						c-0.119,0.08-0.278,0.046-0.355-0.074C46.844,176.742,46.878,176.581,46.998,176.502z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M44.996,173.339c0.122-0.073,0.279-0.033,0.35,0.09c0.069,0.124,0.03,0.283-0.09,0.356
						c-0.124,0.075-0.283,0.036-0.353-0.088C44.832,173.573,44.874,173.413,44.996,173.339z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M43.13,170.093c0.125-0.068,0.285-0.024,0.355,0.099s0.026,0.28-0.098,0.349
						c-0.125,0.069-0.284,0.025-0.355-0.099S43.004,170.162,43.13,170.093z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.527" cy="166.999" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.925" cy="163.616" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.453" cy="160.175" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M37.032,156.435c0.134-0.047,0.281,0.023,0.327,0.158c0.045,0.134-0.027,0.283-0.161,0.331
						c-0.135,0.049-0.281-0.022-0.327-0.157C36.825,156.631,36.897,156.482,37.032,156.435z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M35.839,152.886c0.138-0.042,0.274,0.036,0.322,0.17c0.045,0.134-0.029,0.279-0.165,0.322
						c-0.136,0.043-0.284-0.032-0.33-0.167C35.619,153.076,35.705,152.928,35.839,152.886z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M34.816,149.285c0.138-0.037,0.282,0.044,0.321,0.181c0.039,0.137-0.042,0.278-0.18,0.315
						c-0.138,0.038-0.281-0.044-0.32-0.181C34.598,149.463,34.678,149.322,34.816,149.285z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.999" cy="145.897" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.249" cy="142.231" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M32.604,138.282c0.141-0.02,0.271,0.079,0.289,0.22c0.018,0.141-0.083,0.272-0.224,0.292
						c-0.142,0.021-0.271-0.078-0.289-0.22S32.462,138.302,32.604,138.282z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M32.144,134.567c0.142-0.014,0.264,0.09,0.279,0.231c0.018,0.141-0.083,0.268-0.225,0.283
						c-0.142,0.015-0.272-0.088-0.29-0.23C31.893,134.708,32.002,134.581,32.144,134.567z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.859,130.834c0.142-0.008,0.267,0.1,0.278,0.242c0.011,0.142-0.097,0.264-0.239,0.273
						c-0.143,0.01-0.267-0.099-0.278-0.241C31.61,130.965,31.717,130.842,31.859,130.834z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.731" cy="127.351" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="31.718" cy="123.609" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M31.86,119.61c0.142,0.008,0.25,0.13,0.239,0.272s-0.136,0.25-0.278,0.243
						c-0.143-0.007-0.25-0.129-0.239-0.271C31.593,119.71,31.717,119.602,31.86,119.61z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M32.144,115.877c0.142,0.014,0.249,0.141,0.238,0.282s-0.135,0.246-0.277,0.232
						c-0.143-0.013-0.249-0.14-0.238-0.282C31.877,115.967,32.001,115.863,32.144,115.877z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="32.572" cy="112.418" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.158" cy="108.722" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="33.882" cy="105.05" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M34.817,101.159c0.137,0.037,0.219,0.178,0.18,0.314c-0.039,0.137-0.183,0.218-0.321,0.183
						c-0.139-0.036-0.219-0.177-0.18-0.314C34.535,101.205,34.679,101.123,34.817,101.159z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M35.839,97.558c0.136,0.042,0.216,0.187,0.177,0.324c-0.039,0.136-0.182,0.213-0.318,0.172
						c-0.137-0.042-0.216-0.187-0.177-0.324S35.703,97.516,35.839,97.558z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="36.949" cy="94.254" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.264" cy="90.75" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.712" cy="87.299" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M41.41,83.675c0.127,0.063,0.179,0.218,0.114,0.345c-0.064,0.126-0.222,0.177-0.35,0.115
						c-0.128-0.063-0.18-0.217-0.114-0.344C41.125,83.664,41.282,83.612,41.41,83.675z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M43.13,80.351c0.125,0.068,0.169,0.225,0.099,0.348c-0.071,0.124-0.222,0.173-0.348,0.104
						c-0.125-0.067-0.178-0.228-0.107-0.352C42.846,80.327,43.005,80.282,43.13,80.351z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M44.997,77.105c0.122,0.073,0.164,0.233,0.093,0.356s-0.229,0.164-0.351,0.091
						c-0.123-0.073-0.165-0.232-0.093-0.356S44.874,77.032,44.997,77.105z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="46.859" cy="74.16" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="48.97" cy="71.069" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.198" cy="68.062" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M53.708,64.946c0.108,0.091,0.122,0.254,0.029,0.361c-0.093,0.107-0.258,0.12-0.367,0.029
						c-0.11-0.092-0.123-0.253-0.029-0.361C53.435,64.867,53.599,64.854,53.708,64.946z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M56.169,62.125c0.104,0.096,0.111,0.258,0.013,0.361c-0.094,0.106-0.255,0.116-0.361,0.021
						c-0.105-0.096-0.117-0.262-0.021-0.369C55.898,62.035,56.064,62.029,56.169,62.125z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M58.753,59.416c0.101,0.1,0.104,0.266,0.006,0.369c-0.098,0.103-0.261,0.104-0.362,0.005
						c-0.103-0.1-0.105-0.265-0.006-0.368S58.651,59.315,58.753,59.416z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="61.26" cy="56.995" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="64.042" cy="54.491" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="66.918" cy="52.096" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M70.095,49.664c0.084,0.115,0.059,0.275-0.057,0.358s-0.279,0.055-0.364-0.059
						c-0.085-0.115-0.059-0.275,0.057-0.358C69.848,49.521,70.01,49.548,70.095,49.664z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M73.153,47.505c0.079,0.118,0.047,0.277-0.073,0.354c-0.117,0.081-0.275,0.052-0.355-0.065
						c-0.081-0.118-0.052-0.282,0.066-0.363C72.912,47.353,73.074,47.386,73.153,47.505z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M76.305,45.484c0.074,0.121,0.038,0.283-0.082,0.359s-0.278,0.04-0.354-0.081
						c-0.076-0.121-0.039-0.282,0.081-0.359C76.072,45.326,76.23,45.362,76.305,45.484z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.314" cy="43.727" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="82.612" cy="41.955" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="85.974" cy="40.312" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M89.638,38.703c0.054,0.132-0.009,0.282-0.141,0.334c-0.132,0.052-0.285-0.013-0.339-0.145
						c-0.055-0.132,0.008-0.281,0.141-0.333C89.431,38.506,89.583,38.571,89.638,38.703z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M93.122,37.333c0.048,0.132-0.021,0.281-0.152,0.334c-0.132,0.052-0.28-0.015-0.33-0.148
						c-0.05-0.134,0.018-0.285,0.15-0.337C92.923,37.128,93.072,37.197,93.122,37.333z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M96.668,36.133c0.043,0.135-0.03,0.283-0.165,0.329c-0.135,0.045-0.28-0.029-0.324-0.164
						c-0.044-0.136,0.029-0.283,0.165-0.329C96.479,35.924,96.625,35.997,96.668,36.133z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.01" cy="35.144" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="103.634" cy="34.21" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M107.545,33.369c0.027,0.14-0.065,0.274-0.205,0.299c-0.14,0.025-0.276-0.069-0.304-0.208
						c-0.027-0.141,0.064-0.274,0.205-0.3C107.382,33.135,107.519,33.229,107.545,33.369z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M111.232,32.724c0.021,0.141-0.076,0.271-0.217,0.289c-0.14,0.025-0.271-0.07-0.293-0.21
						c-0.022-0.142,0.074-0.276,0.215-0.301C111.079,32.482,111.211,32.583,111.232,32.724z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M114.946,32.252c0.016,0.141-0.086,0.271-0.227,0.29s-0.269-0.083-0.285-0.225
						c-0.016-0.142,0.085-0.271,0.228-0.29C114.803,32.009,114.931,32.109,114.946,32.252z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.418" cy="31.952" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.156" cy="31.756" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M126.157,31.702c-0.001,0.143-0.118,0.256-0.26,0.252s-0.257-0.123-0.256-0.265
						c0-0.143,0.117-0.256,0.26-0.252S126.158,31.56,126.157,31.702z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M129.899,31.797c-0.007,0.142-0.128,0.256-0.271,0.252c-0.142-0.003-0.252-0.123-0.246-0.265
						c0.006-0.143,0.127-0.256,0.27-0.252C129.796,31.536,129.906,31.654,129.899,31.797z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.375" cy="32.055" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.096" cy="32.455" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.8" cy="32.995" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M144.733,33.731c-0.03,0.139-0.167,0.227-0.305,0.195c-0.139-0.032-0.228-0.172-0.199-0.312
						c0.029-0.14,0.166-0.228,0.305-0.195S144.763,33.592,144.733,33.731z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M148.381,34.573c-0.035,0.138-0.177,0.225-0.315,0.193c-0.139-0.032-0.223-0.171-0.188-0.309
						c0.035-0.139,0.175-0.225,0.314-0.193C148.332,34.296,148.416,34.435,148.381,34.573z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="151.737" cy="35.515" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.303" cy="36.651" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="158.823" cy="37.922" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M162.527,39.435c-0.057,0.13-0.208,0.189-0.338,0.131s-0.188-0.213-0.132-0.344
						c0.056-0.131,0.208-0.19,0.337-0.131C162.525,39.149,162.584,39.303,162.527,39.435z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M165.935,40.984c-0.062,0.129-0.214,0.177-0.344,0.119c-0.129-0.059-0.184-0.211-0.123-0.339
						c0.062-0.129,0.217-0.186,0.347-0.127C165.946,40.695,165.996,40.857,165.935,40.984z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M169.267,42.691c-0.067,0.125-0.225,0.175-0.351,0.11c-0.126-0.064-0.175-0.22-0.108-0.346
						c0.067-0.126,0.224-0.176,0.351-0.111C169.286,42.41,169.334,42.565,169.267,42.691z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.302" cy="44.403" r="0.258" />
                </g>
                <g>
                  <a id="p38" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="175.495" cy="46.355" r="0.258" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="178.611" cy="48.429" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M181.849,50.781c-0.086,0.113-0.248,0.134-0.359,0.046c-0.112-0.087-0.132-0.252-0.047-0.366
						c0.086-0.114,0.247-0.135,0.359-0.047C181.914,50.503,181.936,50.667,181.849,50.781z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M184.79,53.097c-0.09,0.109-0.252,0.124-0.365,0.037c-0.112-0.088-0.128-0.25-0.039-0.359
						c0.09-0.111,0.255-0.129,0.367-0.041C184.867,52.821,184.881,52.986,184.79,53.097z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M187.619,55.548c-0.095,0.106-0.26,0.117-0.367,0.024c-0.107-0.093-0.117-0.256-0.023-0.362
						c0.095-0.107,0.259-0.118,0.367-0.024C187.705,55.28,187.715,55.442,187.619,55.548z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="190.163" cy="57.93" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="192.803" cy="60.584" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="195.339" cy="63.336" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M197.928,66.387c-0.11,0.089-0.272,0.072-0.36-0.04c-0.087-0.112-0.069-0.276,0.042-0.367
						s0.271-0.073,0.36,0.04C198.057,66.133,198.039,66.297,197.928,66.387z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M200.237,69.333c-0.114,0.085-0.273,0.06-0.363-0.05c-0.088-0.112-0.066-0.273,0.047-0.358
						c0.114-0.086,0.278-0.065,0.366,0.047C200.378,69.083,200.352,69.248,200.237,69.333z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M202.407,72.385c-0.117,0.08-0.28,0.052-0.363-0.063c-0.082-0.116-0.053-0.276,0.063-0.357
						c0.118-0.082,0.28-0.053,0.363,0.063C202.553,72.144,202.525,72.304,202.407,72.385z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="204.314" cy="75.301" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.25" cy="78.505" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.061" cy="81.78" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.852,85.358c-0.128,0.061-0.282,0.005-0.34-0.124c-0.058-0.129,0-0.285,0.127-0.346
						c0.129-0.062,0.281-0.006,0.34,0.124C210.038,85.142,209.981,85.297,209.852,85.358z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M211.394,88.77c-0.131,0.055-0.286-0.004-0.344-0.134s0.001-0.281,0.132-0.337
						c0.131-0.057,0.285,0.003,0.344,0.134S211.526,88.714,211.394,88.77z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.675" cy="92.011" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="213.927" cy="95.539" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="215.043" cy="99.111" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M216.08,102.975c-0.138,0.034-0.277-0.051-0.31-0.19c-0.032-0.138,0.056-0.279,0.193-0.313
						c0.139-0.035,0.277,0.05,0.31,0.189C216.305,102.801,216.219,102.941,216.08,102.975z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M216.909,106.625c-0.139,0.028-0.274-0.062-0.299-0.203c-0.032-0.138,0.056-0.274,0.195-0.303
						c0.14-0.029,0.28,0.061,0.312,0.2C217.143,106.46,217.049,106.597,216.909,106.625z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M217.567,110.311c-0.14,0.022-0.276-0.073-0.301-0.213c-0.025-0.14,0.07-0.272,0.21-0.295
						c0.141-0.023,0.276,0.072,0.301,0.212C217.803,110.156,217.708,110.289,217.567,110.311z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.04" cy="113.763" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="218.42" cy="117.487" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M218.669,121.48c-0.142,0.005-0.261-0.105-0.265-0.247c-0.003-0.142,0.11-0.263,0.252-0.269
						c0.143-0.006,0.262,0.104,0.265,0.247C218.925,121.354,218.812,121.475,218.669,121.48z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M218.764,125.223c-0.142,0-0.262-0.115-0.265-0.257c-0.004-0.142,0.11-0.258,0.252-0.259
						c0.143-0.001,0.262,0.114,0.266,0.257C219.02,125.107,218.907,125.223,218.764,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path fill="#FFFFFF" d="M213,128.978c-0.142-0.006-0.256-0.126-0.252-0.269c0.004-0.143,0.124-0.254,0.265-0.249
						c0.143,0.005,0.256,0.126,0.252,0.269C213.261,128.872,213.143,128.984,213,128.978z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.773" cy="132.467" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.388" cy="136.202" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.848" cy="139.918" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M211.095,143.861c-0.139-0.03-0.227-0.167-0.195-0.307c0.032-0.139,0.172-0.228,0.311-0.198
						c0.14,0.029,0.228,0.167,0.195,0.306C211.374,143.802,211.235,143.891,211.095,143.861z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M210.234,147.517c-0.138-0.036-0.219-0.177-0.18-0.314c0.04-0.136,0.173-0.222,0.312-0.187
						c0.137,0.035,0.229,0.179,0.188,0.316C210.516,147.47,210.372,147.553,210.234,147.517z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.208,151.13c-0.136-0.042-0.215-0.188-0.177-0.325c0.039-0.137,0.182-0.214,0.318-0.173
						c0.137,0.041,0.216,0.187,0.177,0.325C209.487,151.094,209.344,151.172,209.208,151.13z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M208.015,154.691c-0.134-0.047-0.207-0.196-0.161-0.332c0.045-0.135,0.193-0.206,0.327-0.159
						c0.135,0.047,0.207,0.196,0.161,0.332C208.296,154.668,208.149,154.739,208.015,154.691z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.768" cy="157.958" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.292" cy="161.41" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.672" cy="164.798" r="0.259" />
                </g>
                <g>
                  <a id="p39" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="201.91" cy="168.114" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M199.871,171.572c-0.121-0.075-0.157-0.234-0.08-0.354c0.077-0.12,0.239-0.156,0.36-0.082
						c0.122,0.075,0.158,0.233,0.08,0.354C200.153,171.61,199.992,171.647,199.871,171.572z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M197.826,174.722c-0.118-0.08-0.147-0.24-0.064-0.356c0.083-0.115,0.245-0.146,0.361-0.067
						c0.12,0.081,0.151,0.242,0.067,0.358C198.106,174.773,197.943,174.803,197.826,174.722z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M195.649,177.783c-0.114-0.085-0.141-0.249-0.058-0.365c0.083-0.116,0.243-0.141,0.357-0.057
						c0.115,0.085,0.141,0.249,0.058,0.366C195.923,177.843,195.763,177.869,195.649,177.783z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.329,180.737c-0.11-0.09-0.129-0.255-0.041-0.367c0.088-0.112,0.25-0.13,0.361-0.041
						c0.111,0.089,0.129,0.253,0.041,0.366C193.601,180.809,193.439,180.827,193.329,180.737z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="191.057" cy="183.395" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="188.507" cy="186.152" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="185.845" cy="188.8" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="183.074" cy="191.335" r="0.259" />
                </g>
                <g>
                  <a id="p40" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="180.2" cy="193.751" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M177.015,196.195c-0.083-0.115-0.058-0.276,0.058-0.359c0.116-0.083,0.28-0.056,0.364,0.059
						c0.085,0.115,0.059,0.275-0.058,0.358C177.263,196.336,177.1,196.311,177.015,196.195z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M173.941,198.353c-0.079-0.118-0.046-0.278,0.074-0.355c0.12-0.077,0.283-0.042,0.361,0.074
						c0.081,0.12,0.048,0.278-0.073,0.355C174.183,198.505,174.021,198.472,173.941,198.353z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M170.779,200.379c-0.073-0.121-0.037-0.284,0.083-0.36c0.12-0.077,0.278-0.04,0.353,0.081
						c0.075,0.122,0.038,0.283-0.083,0.36C171.011,200.538,170.854,200.502,170.779,200.379z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M167.522,202.251c-0.068-0.125-0.024-0.284,0.099-0.355c0.124-0.071,0.281-0.027,0.35,0.097
						c0.07,0.125,0.025,0.284-0.099,0.355C167.748,202.42,167.591,202.377,167.522,202.251z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="164.421" cy="203.866" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="161.025" cy="205.468" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.564" cy="206.925" r="0.259" />
                </g>
                <g>
                  <a id="p41" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="154.045" cy="208.235" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M150.224,209.466c-0.041-0.136,0.038-0.28,0.175-0.319s0.282,0.041,0.323,0.177
							c0.042,0.137-0.037,0.279-0.175,0.319C150.409,209.683,150.265,209.604,150.224,209.466z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.604,210.464c-0.035-0.138,0.05-0.277,0.189-0.31c0.139-0.032,0.28,0.055,0.315,0.193
						c0.036,0.139-0.049,0.277-0.188,0.309C146.779,210.688,146.638,210.603,146.604,210.464z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M142.943,211.309c-0.028-0.14,0.061-0.28,0.2-0.312c0.139-0.032,0.276,0.056,0.305,0.195
						c0.03,0.14-0.06,0.279-0.2,0.312S142.972,211.449,142.943,211.309z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M139.246,211.969c-0.022-0.14,0.073-0.276,0.213-0.301c0.14-0.025,0.273,0.07,0.296,0.21
						c0.023,0.141-0.072,0.275-0.213,0.301C139.401,212.205,139.269,212.11,139.246,211.969z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.782" cy="212.443" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.044" cy="212.807" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.295" cy="213.019" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M124.282,213.073c0.001-0.142,0.118-0.256,0.261-0.252c0.143,0.003,0.258,0.123,0.257,0.265
						c0,0.143-0.117,0.256-0.261,0.252C124.395,213.334,124.28,213.216,124.282,213.073z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M120.527,212.969c0.008-0.144,0.129-0.245,0.271-0.243c0.143,0.003,0.253,0.123,0.246,0.265
						c-0.006,0.143-0.128,0.255-0.271,0.252C120.629,213.241,120.52,213.11,120.527,212.969z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.782,212.685c0.014-0.142,0.14-0.249,0.282-0.238s0.247,0.136,0.234,0.277
						c-0.013,0.143-0.139,0.249-0.282,0.238S116.768,212.827,116.782,212.685z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.31" cy="212.271" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="109.601" cy="211.685" r="0.259" />
                </g>
                <g>
                  <a id="p42" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="105.919" cy="210.944" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.023,209.979c0.037-0.137,0.179-0.218,0.316-0.179c0.137,0.039,0.218,0.184,0.182,0.321
						c-0.037,0.138-0.178,0.218-0.316,0.179S101.985,210.117,102.023,209.979z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M98.42,208.919c0.043-0.135,0.188-0.209,0.324-0.164c0.135,0.046,0.209,0.194,0.167,0.33
						c-0.043,0.137-0.188,0.21-0.323,0.164C98.452,209.203,98.376,209.056,98.42,208.919z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M94.865,207.708c0.05-0.135,0.196-0.196,0.332-0.153c0.135,0.045,0.205,0.192,0.156,0.326
							c-0.048,0.135-0.198,0.206-0.334,0.16C94.882,207.999,94.816,207.841,94.865,207.708z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M91.371,206.331c0.055-0.131,0.208-0.196,0.34-0.144c0.132,0.052,0.196,0.203,0.142,0.334
						c-0.054,0.132-0.207,0.197-0.34,0.144C91.379,206.612,91.315,206.463,91.371,206.331z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.177" cy="204.907" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.809" cy="203.246" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.515" cy="201.444" r="0.259" />
                </g>
                <g>
                  <a id="p43" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="78.299" cy="199.506" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="75.167" cy="197.433" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M71.921,195.071c0.086-0.113,0.248-0.134,0.36-0.046c0.112,0.088,0.133,0.252,0.047,0.366
						c-0.086,0.114-0.248,0.135-0.36,0.046C71.855,195.349,71.834,195.185,71.921,195.071z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M68.981,192.733c0.091-0.109,0.253-0.123,0.361-0.029c0.106,0.094,0.122,0.258,0.032,0.366
						c-0.092,0.112-0.255,0.127-0.363,0.032C68.904,193.008,68.89,192.843,68.981,192.733z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M66.143,190.274c0.096-0.105,0.262-0.116,0.369-0.022c0.108,0.093,0.117,0.256,0.022,0.361
						c-0.096,0.106-0.261,0.117-0.369,0.022C66.056,190.542,66.046,190.38,66.143,190.274z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M63.424,187.683c0.1-0.101,0.266-0.104,0.369-0.005c0.103,0.098,0.105,0.261,0.006,0.363
						c-0.1,0.103-0.265,0.105-0.369,0.006S63.323,187.784,63.424,187.683z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="60.995" cy="185.167" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="58.493" cy="182.367" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="56.112" cy="179.463" r="0.259" />
                </g>
                <g>
                  <a id="p44" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="53.854" cy="176.463" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="51.723" cy="173.371" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M49.596,169.967c0.122-0.073,0.28-0.032,0.351,0.092c0.071,0.124,0.029,0.284-0.093,0.357
						c-0.123,0.073-0.28,0.032-0.352-0.092C49.431,170.199,49.473,170.04,49.596,169.967z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M47.743,166.7c0.125-0.067,0.281-0.019,0.346,0.108c0.063,0.127,0.016,0.285-0.107,0.352
						c-0.127,0.069-0.284,0.022-0.348-0.106C47.568,166.926,47.617,166.768,47.743,166.7z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M46.031,163.356c0.128-0.062,0.286-0.009,0.351,0.118s0.013,0.281-0.115,0.344
						c-0.128,0.063-0.285,0.01-0.351-0.118C45.851,163.573,45.902,163.419,46.031,163.356z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M44.481,159.935c0.13-0.056,0.285,0.003,0.344,0.133c0.059,0.13-0.001,0.282-0.131,0.339
						c-0.131,0.058-0.285-0.002-0.344-0.133S44.35,159.991,44.481,159.935z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="43.174" cy="156.692" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.907" cy="153.157" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="40.791" cy="149.572" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.826" cy="145.943" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.969,142.021c0.14-0.027,0.274,0.065,0.299,0.205c0.025,0.14-0.069,0.277-0.208,0.305
						c-0.141,0.028-0.274-0.064-0.3-0.205C38.735,142.185,38.828,142.048,38.969,142.021z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.323,138.321c0.141-0.021,0.271,0.077,0.289,0.218c0.022,0.141-0.071,0.272-0.212,0.294
						c-0.141,0.022-0.277-0.075-0.3-0.216C38.082,138.475,38.181,138.342,38.323,138.321z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M37.849,134.595c0.141-0.015,0.272,0.087,0.29,0.229c0.018,0.141-0.083,0.269-0.225,0.285
						c-0.142,0.016-0.272-0.086-0.29-0.229C37.606,134.738,37.707,134.61,37.849,134.595z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.564" cy="131.11" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.4" cy="127.359" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="37.387" cy="123.604" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M37.545,119.593c0.142,0.009,0.25,0.132,0.239,0.274c-0.011,0.142-0.136,0.25-0.278,0.243
						c-0.143-0.008-0.25-0.131-0.239-0.274C37.278,119.692,37.402,119.583,37.545,119.593z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M37.849,115.85c0.142,0.015,0.243,0.142,0.225,0.283c-0.021,0.141-0.142,0.245-0.284,0.231
						c-0.142-0.014-0.252-0.142-0.231-0.284C37.577,115.938,37.707,115.834,37.849,115.85z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M38.323,112.123c0.141,0.021,0.241,0.153,0.223,0.295c-0.018,0.141-0.148,0.239-0.289,0.219
						c-0.142-0.021-0.241-0.152-0.223-0.294C38.052,112.2,38.181,112.102,38.323,112.123z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="38.923" cy="108.678" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="39.71" cy="105.006" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="40.649" cy="101.371" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="41.741" cy="97.778" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M43.079,93.993c0.133,0.051,0.199,0.2,0.147,0.333c-0.052,0.132-0.204,0.199-0.337,0.149
						c-0.134-0.05-0.2-0.199-0.147-0.332C42.794,94.009,42.945,93.942,43.079,93.993z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M44.481,90.51c0.131,0.056,0.19,0.208,0.131,0.338c-0.059,0.129-0.214,0.189-0.345,0.134
						c-0.132-0.056-0.19-0.207-0.131-0.338S44.35,90.453,44.481,90.51z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M46.031,87.088c0.126,0.061,0.183,0.217,0.125,0.347c-0.059,0.129-0.212,0.185-0.34,0.124
						c-0.129-0.062-0.186-0.217-0.126-0.348C45.748,87.08,45.9,87.025,46.031,87.088z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M47.743,83.745c0.125,0.067,0.175,0.226,0.11,0.353c-0.065,0.126-0.221,0.175-0.347,0.109
						c-0.126-0.067-0.176-0.225-0.11-0.353C47.462,83.726,47.617,83.677,47.743,83.745z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="49.467" cy="80.703" r="0.259" />
                </g>
                <g>
                  <a id="p45" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="51.444" cy="77.51" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="53.554" cy="74.404" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="55.792" cy="71.388" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="58.155" cy="68.47" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M60.816,65.465c0.104,0.097,0.11,0.26,0.011,0.363c-0.098,0.103-0.264,0.108-0.369,0.012
						c-0.105-0.096-0.111-0.259-0.012-0.363S60.711,65.367,60.816,65.465z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M63.424,62.762c0.1,0.101,0.099,0.264-0.005,0.362c-0.104,0.098-0.269,0.096-0.368-0.003
						c-0.103-0.103-0.101-0.266,0.003-0.364C63.158,62.658,63.323,62.66,63.424,62.762z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M66.143,60.17c0.095,0.104,0.09,0.271-0.014,0.37c-0.103,0.098-0.266,0.092-0.362-0.013
						c-0.097-0.105-0.091-0.271,0.013-0.37C65.884,60.059,66.046,60.063,66.143,60.17z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M68.982,57.711c0.091,0.109,0.078,0.274-0.029,0.368c-0.108,0.093-0.271,0.08-0.362-0.029
						c-0.092-0.109-0.079-0.274,0.029-0.368C68.729,57.588,68.891,57.601,68.982,57.711z" />
                </g>
                <g>
                  <a id="p46" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="71.718" cy="55.533" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.745" cy="53.312" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.863" cy="51.219" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.066" cy="49.259" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.348" cy="47.435" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M87.941,45.644c0.061,0.128,0.005,0.282-0.125,0.34c-0.129,0.059-0.285,0.001-0.346-0.127
						c-0.062-0.129-0.005-0.282,0.125-0.341C87.725,45.458,87.88,45.514,87.941,45.644z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M91.371,44.114c0.055,0.131-0.008,0.281-0.141,0.333c-0.134,0.05-0.283-0.005-0.339-0.137
							c-0.056-0.13,0.003-0.29,0.138-0.34C91.163,43.917,91.316,43.982,91.371,44.114z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M94.865,42.736c0.049,0.133-0.018,0.286-0.151,0.338c-0.132,0.052-0.281-0.015-0.331-0.148
						c-0.05-0.134,0.018-0.285,0.151-0.337C94.668,42.536,94.816,42.602,94.865,42.736z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M98.421,41.525c0.043,0.136-0.031,0.284-0.166,0.33c-0.135,0.045-0.281-0.028-0.325-0.163
						c-0.044-0.136,0.03-0.284,0.166-0.33C98.232,41.316,98.377,41.389,98.421,41.525z" />
                </g>
                <g>
                  <a id="p47" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="101.774" cy="40.536" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="105.415" cy="39.617" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="109.091" cy="38.851" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M113.053,38.206c0.02,0.141-0.08,0.271-0.221,0.289c-0.142,0.018-0.273-0.083-0.293-0.224
						c-0.021-0.142,0.079-0.271,0.221-0.289C112.902,37.965,113.034,38.064,113.053,38.206z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.782,37.76c0.013,0.142-0.091,0.267-0.233,0.277c-0.142,0.011-0.269-0.097-0.283-0.238
						c-0.015-0.143,0.09-0.267,0.233-0.277S116.769,37.618,116.782,37.76z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M120.527,37.476c0.007,0.142-0.102,0.267-0.244,0.278c-0.142,0.011-0.264-0.097-0.272-0.239
						c-0.008-0.143,0.101-0.267,0.244-0.278S120.52,37.333,120.527,37.476z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="124.023" cy="37.378" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.778" cy="37.413" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.528" cy="37.598" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M135.525,37.97c-0.016,0.141-0.145,0.242-0.286,0.224c-0.141-0.018-0.243-0.148-0.228-0.29
						c0.016-0.142,0.144-0.243,0.286-0.225C135.44,37.698,135.542,37.828,135.525,37.97z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M139.247,38.476c-0.023,0.14-0.155,0.235-0.295,0.21c-0.14-0.025-0.236-0.161-0.215-0.301
						c0.022-0.141,0.154-0.235,0.295-0.21C139.173,38.2,139.269,38.334,139.247,38.476z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M142.944,39.137c-0.028,0.138-0.166,0.232-0.306,0.207c-0.14-0.025-0.231-0.16-0.204-0.299
						c0.028-0.141,0.165-0.233,0.306-0.208C142.881,38.862,142.973,38.995,142.944,39.137z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.604,39.981c-0.035,0.138-0.176,0.225-0.315,0.193c-0.139-0.032-0.224-0.171-0.19-0.31
						c0.034-0.139,0.174-0.225,0.314-0.193C146.553,39.704,146.639,39.843,146.604,39.981z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.975" cy="40.908" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="153.554" cy="42.044" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.082" cy="43.33" r="0.259" />
                </g>
                <g>
                  <a id="p48" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="160.553" cy="44.763" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M164.191,46.461c-0.063,0.127-0.218,0.179-0.345,0.114c-0.126-0.065-0.178-0.222-0.116-0.35
						c0.063-0.128,0.217-0.18,0.345-0.114C164.202,46.176,164.254,46.333,164.191,46.461z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M167.523,48.193c-0.069,0.125-0.225,0.169-0.349,0.098c-0.124-0.071-0.168-0.231-0.102-0.354
						c0.069-0.127,0.226-0.171,0.35-0.1C167.547,47.909,167.592,48.068,167.523,48.193z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M170.779,50.066c-0.074,0.121-0.235,0.164-0.358,0.092c-0.124-0.071-0.164-0.229-0.091-0.351
						c0.073-0.123,0.234-0.164,0.358-0.093C170.812,49.787,170.854,49.943,170.779,50.066z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M173.941,52.093c-0.079,0.118-0.241,0.153-0.361,0.076c-0.12-0.077-0.153-0.237-0.075-0.355
						c0.079-0.12,0.24-0.153,0.361-0.076C173.987,51.814,174.021,51.974,173.941,52.093z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.804" cy="54.1" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.793" cy="56.374" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="182.683" cy="58.771" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="185.471" cy="61.288" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="188.15" cy="63.918" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M190.887,66.854c-0.106,0.095-0.269,0.084-0.362-0.023c-0.093-0.107-0.082-0.273,0.023-0.368
						c0.106-0.096,0.269-0.085,0.362,0.023C191.005,66.595,190.995,66.76,190.887,66.854z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.329,69.708c-0.11,0.09-0.272,0.073-0.36-0.04s-0.069-0.277,0.039-0.367
							c0.112-0.092,0.273-0.075,0.361,0.038C193.458,69.454,193.44,69.618,193.329,69.708z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M195.649,72.663c-0.112,0.083-0.278,0.063-0.366-0.049s-0.066-0.273,0.047-0.359
						c0.114-0.086,0.278-0.064,0.367,0.048S195.765,72.576,195.649,72.663z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M197.826,75.724c-0.117,0.08-0.281,0.051-0.363-0.065c-0.083-0.116-0.054-0.276,0.063-0.357
						c0.118-0.082,0.28-0.052,0.363,0.064C197.972,75.483,197.944,75.643,197.826,75.724z" />
                </g>
                <g>
                  <a id="p49" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="199.731" cy="78.656" r="0.259" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="201.653" cy="81.882" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="203.436" cy="85.186" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.079" cy="88.563" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="206.578" cy="92.006" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M208.015,95.754c-0.134,0.047-0.281-0.023-0.326-0.158c-0.046-0.135,0.027-0.284,0.16-0.333
						c0.135-0.049,0.281,0.021,0.327,0.158C208.221,95.557,208.15,95.706,208.015,95.754z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.208,99.315c-0.136,0.042-0.277-0.035-0.319-0.172c-0.046-0.135,0.028-0.279,0.164-0.322
						c0.136-0.043,0.284,0.033,0.33,0.168C209.424,99.127,209.344,99.274,209.208,99.315z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M210.234,102.929c-0.138,0.036-0.282-0.046-0.321-0.183c-0.039-0.137,0.042-0.278,0.18-0.315
						c0.138-0.037,0.282,0.045,0.321,0.183C210.452,102.751,210.372,102.893,210.234,102.929z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.037" cy="106.332" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="211.757" cy="110.018" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.322" cy="113.73" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="212.734" cy="117.462" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M213,121.468c-0.142,0.006-0.262-0.105-0.265-0.248c-0.004-0.142,0.11-0.264,0.252-0.271
						c0.143-0.007,0.261,0.104,0.265,0.248C213.255,121.341,213.143,121.462,213,121.468z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M213.095,125.223c-0.142,0-0.262-0.116-0.265-0.258c-0.004-0.143,0.11-0.259,0.252-0.26
						c0.143,0,0.262,0.115,0.266,0.258C213.351,125.106,213.238,125.223,213.095,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path fill="#FFFFFF" d="M159.021,128.964c-0.141-0.016-0.24-0.142-0.218-0.282c0.008-0.141,0.129-0.245,0.271-0.231
						c0.142,0.013,0.252,0.141,0.244,0.284C159.295,128.877,159.163,128.979,159.021,128.964z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M158.396,132.658c-0.138-0.031-0.224-0.168-0.188-0.305c0.035-0.137,0.178-0.225,0.317-0.196
						c0.14,0.029,0.225,0.166,0.188,0.305C158.678,132.601,158.536,132.689,158.396,132.658z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M157.37,136.259c-0.134-0.046-0.205-0.192-0.156-0.325c0.048-0.133,0.199-0.204,0.334-0.161
						c0.136,0.044,0.206,0.19,0.157,0.325S157.505,136.306,157.37,136.259z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M155.955,139.726c-0.128-0.061-0.184-0.214-0.123-0.342c0.062-0.127,0.218-0.183,0.347-0.124
						c0.13,0.059,0.185,0.212,0.123,0.341C156.239,139.731,156.084,139.787,155.955,139.726z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="154.304" cy="142.797" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.194" cy="145.89" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M149.588,148.929c-0.102-0.099-0.108-0.266-0.013-0.371c0.095-0.104,0.257-0.109,0.36-0.012
						c0.104,0.098,0.11,0.264,0.013,0.371C149.852,149.023,149.691,149.028,149.588,148.929z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.845,151.48c-0.088-0.107-0.079-0.276,0.026-0.371c0.104-0.095,0.265-0.084,0.357,0.024
						c0.093,0.109,0.082,0.275-0.025,0.372C147.096,151.603,146.938,151.593,146.845,151.48z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M143.816,153.686c-0.078-0.119-0.043-0.276,0.078-0.35c0.12-0.074,0.285-0.035,0.363,0.08
						c0.083,0.122,0.046,0.276-0.076,0.352C144.058,153.842,143.895,153.806,143.816,153.686z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M140.568,155.55c-0.064-0.127-0.013-0.281,0.114-0.342s0.285-0.008,0.352,0.118
						c0.067,0.126,0.016,0.28-0.114,0.342C140.79,155.73,140.632,155.678,140.568,155.55z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M137.137,157.05c-0.049-0.133,0.018-0.282,0.15-0.331c0.133-0.048,0.283,0.021,0.335,0.153
						c0.052,0.133-0.015,0.281-0.15,0.331S137.187,157.184,137.137,157.05z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.813" cy="158.101" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.143" cy="158.842" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M126.156,159.191c-0.004-0.142,0.108-0.264,0.25-0.271c0.141-0.007,0.261,0.104,0.267,0.246
						c0.006,0.143-0.105,0.264-0.25,0.271C126.28,159.444,126.16,159.334,126.156,159.191z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M122.413,159.097c0.012-0.142,0.136-0.252,0.277-0.245c0.141,0.007,0.248,0.129,0.239,0.271
						c-0.009,0.143-0.133,0.252-0.277,0.245S122.4,159.239,122.413,159.097z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M118.7,158.598c0.027-0.139,0.163-0.237,0.303-0.215c0.14,0.021,0.233,0.153,0.208,0.293
						c-0.025,0.141-0.161,0.237-0.303,0.215C118.766,158.87,118.672,158.739,118.7,158.598z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M115.064,157.694c0.042-0.132,0.187-0.213,0.324-0.179c0.137,0.035,0.215,0.176,0.175,0.312
						c-0.041,0.137-0.187,0.219-0.326,0.183C115.098,157.975,115.02,157.833,115.064,157.694z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M111.562,156.363c0.057-0.13,0.208-0.187,0.335-0.125c0.126,0.063,0.187,0.217,0.133,0.345
						c-0.057,0.135-0.208,0.194-0.337,0.13C111.562,156.651,111.503,156.494,111.562,156.363z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M108.227,154.659c0.071-0.123,0.228-0.164,0.349-0.091c0.121,0.074,0.162,0.235,0.093,0.359
						c-0.07,0.125-0.226,0.166-0.349,0.091S108.154,154.782,108.227,154.659z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.097,152.603c0.084-0.114,0.246-0.14,0.359-0.055c0.113,0.084,0.138,0.249,0.055,0.364
						c-0.083,0.117-0.243,0.141-0.358,0.055C105.037,152.881,105.012,152.718,105.097,152.603z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.399" cy="150.395" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="99.766" cy="147.733" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M97.279,144.595c0.117-0.081,0.282-0.055,0.366,0.058c0.085,0.113,0.059,0.273-0.057,0.356
						c-0.116,0.083-0.28,0.058-0.366-0.058C97.136,144.836,97.161,144.677,97.279,144.595z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M95.299,141.414c0.122-0.065,0.286-0.024,0.359,0.097c0.073,0.121,0.031,0.276-0.092,0.346
						c-0.125,0.07-0.286,0.027-0.361-0.096C95.131,141.638,95.17,141.484,95.299,141.414z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M93.715,138.019c0.131-0.053,0.279,0.01,0.328,0.144c0.047,0.133-0.019,0.286-0.146,0.34
						c-0.135,0.057-0.283-0.006-0.332-0.142C93.516,138.226,93.583,138.073,93.715,138.019z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M92.511,134.472c0.137-0.038,0.278,0.042,0.313,0.179c0.035,0.137-0.048,0.281-0.184,0.322
						c-0.137,0.041-0.277-0.039-0.313-0.178C92.292,134.655,92.374,134.511,92.511,134.472z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M91.703,130.816c0.14-0.023,0.272,0.071,0.294,0.211c0.021,0.14-0.076,0.274-0.216,0.299
						c-0.141,0.026-0.272-0.069-0.294-0.211C91.465,130.974,91.562,130.839,91.703,130.816z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.312" cy="127.352" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.286" cy="123.608" r="0.259" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.664" cy="119.884" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M92.512,115.972c0.137,0.039,0.22,0.182,0.185,0.319c-0.035,0.137-0.177,0.218-0.314,0.182
						c-0.138-0.036-0.221-0.179-0.185-0.318C92.233,116.015,92.374,115.933,92.512,115.972z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M93.715,112.426c0.132,0.054,0.2,0.206,0.152,0.338c-0.049,0.133-0.197,0.198-0.33,0.147
						c-0.133-0.052-0.201-0.203-0.151-0.338C93.436,112.438,93.583,112.372,93.715,112.426z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M95.3,109.03c0.125,0.068,0.177,0.227,0.116,0.354c-0.062,0.127-0.215,0.177-0.341,0.111
						c-0.127-0.066-0.179-0.225-0.116-0.354S95.174,108.962,95.3,109.03z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M97.279,105.849c0.117,0.082,0.144,0.24,0.059,0.354c-0.088,0.111-0.247,0.142-0.362,0.065
						c-0.121-0.082-0.154-0.244-0.065-0.357C96.997,105.795,97.162,105.768,97.279,105.849z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M99.592,102.903c0.107,0.093,0.118,0.255,0.022,0.36c-0.095,0.104-0.261,0.114-0.37,0.023
						c-0.109-0.092-0.119-0.253-0.022-0.36C99.319,102.819,99.484,102.809,99.592,102.903z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.208,100.224c0.096,0.104,0.09,0.268-0.015,0.363c-0.104,0.095-0.27,0.088-0.368-0.015
						c-0.099-0.104-0.092-0.266,0.014-0.363C101.947,100.112,102.111,100.119,102.208,100.224z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.89" cy="97.997" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.006" cy="95.92" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M111.562,94.081c0.057,0.13,0,0.287-0.127,0.349s-0.279,0.005-0.339-0.124
						c-0.06-0.13-0.002-0.286,0.127-0.348C111.353,93.895,111.504,93.95,111.562,94.081z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M115.064,92.751c0.042,0.136-0.031,0.287-0.164,0.335c-0.133,0.049-0.277-0.022-0.322-0.157
						c-0.045-0.136,0.028-0.286,0.164-0.335S115.022,92.614,115.064,92.751z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M118.7,91.847c0.027,0.139-0.064,0.271-0.204,0.292c-0.14,0.02-0.273-0.055-0.303-0.197
						c-0.029-0.136,0.058-0.29,0.2-0.31C118.536,91.61,118.673,91.706,118.7,91.847z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M122.413,91.348c0.012,0.142-0.094,0.264-0.235,0.271s-0.268-0.104-0.281-0.245
						c-0.014-0.143,0.091-0.264,0.235-0.271C122.275,91.096,122.401,91.206,122.413,91.348z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M126.157,91.254c-0.004,0.142-0.122,0.253-0.264,0.246c-0.141-0.007-0.254-0.13-0.253-0.272
						c0.001-0.143,0.12-0.253,0.263-0.246C126.047,90.989,126.161,91.111,126.157,91.254z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M129.888,91.563c-0.02,0.141-0.149,0.239-0.289,0.218c-0.14-0.021-0.239-0.154-0.222-0.295
						c0.017-0.142,0.146-0.239,0.289-0.218C129.809,91.29,129.908,91.422,129.888,91.563z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M133.563,92.28c-0.035,0.138-0.175,0.222-0.312,0.187c-0.137-0.035-0.222-0.177-0.189-0.315
						c0.033-0.139,0.172-0.223,0.312-0.187C133.514,92,133.599,92.142,133.563,92.28z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.895" cy="93.306" r="0.258" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.335" cy="94.783" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M143.816,96.759c-0.078,0.119-0.24,0.157-0.361,0.083c-0.121-0.074-0.157-0.232-0.081-0.352
						c0.077-0.121,0.238-0.158,0.361-0.083C143.859,96.482,143.895,96.64,143.816,96.759z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.845,98.965c-0.09,0.109-0.257,0.131-0.37,0.046c-0.113-0.084-0.132-0.245-0.044-0.355
						c0.089-0.112,0.255-0.132,0.37-0.046C146.917,98.695,146.937,98.854,146.845,98.965z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M149.588,101.517c-0.102,0.099-0.263,0.095-0.358-0.01c-0.094-0.105-0.091-0.271,0.007-0.37
						c0.103-0.104,0.265-0.102,0.361,0.006C149.696,101.25,149.691,101.417,149.588,101.517z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M152.04,104.349c-0.112,0.087-0.273,0.067-0.358-0.046c-0.084-0.113-0.062-0.279,0.048-0.368
						c0.111-0.09,0.271-0.069,0.357,0.046C152.174,104.096,152.152,104.261,152.04,104.349z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M154.17,107.428c-0.121,0.074-0.28,0.037-0.354-0.083c-0.073-0.121-0.035-0.282,0.085-0.358
						c0.121-0.077,0.279-0.04,0.354,0.083S154.292,107.353,154.17,107.428z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.843" cy="110.486" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M157.37,114.186c-0.135,0.046-0.285-0.024-0.333-0.157c-0.048-0.133,0.022-0.28,0.156-0.329
						c0.134-0.049,0.283,0.021,0.333,0.157C157.575,113.993,157.505,114.14,157.37,114.186z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M158.396,117.788c-0.139,0.031-0.282-0.055-0.317-0.192s0.05-0.275,0.188-0.309
						c0.139-0.034,0.281,0.053,0.316,0.192C158.62,117.619,158.536,117.757,158.396,117.788z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M159.021,121.481c-0.141,0.015-0.274-0.085-0.296-0.226c-0.021-0.14,0.077-0.268,0.218-0.286
						c0.142-0.018,0.274,0.083,0.296,0.226C159.26,121.338,159.163,121.466,159.021,121.481z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M159.236,125.223c-0.142,0-0.265-0.115-0.272-0.256c-0.007-0.141,0.104-0.258,0.246-0.26
						c0.143-0.002,0.265,0.113,0.271,0.256C159.489,125.106,159.379,125.223,159.236,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="85.242" cy="128.697" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M84.292,132.58c-0.134-0.046-0.206-0.191-0.157-0.324c0.049-0.132,0.199-0.205,0.335-0.162
						c0.136,0.043,0.207,0.188,0.157,0.324C84.576,132.555,84.427,132.627,84.292,132.58z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M82.806,136.017c-0.125-0.068-0.178-0.227-0.117-0.354s0.214-0.177,0.341-0.112
						c0.127,0.065,0.179,0.224,0.117,0.354C83.084,136.035,82.931,136.085,82.806,136.017z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="80.893" cy="138.932" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M78.2,141.888c-0.096-0.105-0.09-0.268,0.014-0.362c0.104-0.095,0.27-0.088,0.369,0.014
						c0.1,0.103,0.093,0.265-0.014,0.362C78.462,142,78.297,141.993,78.2,141.888z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M75.255,144.198c-0.078-0.119-0.043-0.276,0.077-0.349c0.12-0.073,0.272-0.054,0.355,0.066
						c0.08,0.115,0.057,0.289-0.067,0.364S75.333,144.318,75.255,144.198z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M71.964,145.983c-0.057-0.13-0.001-0.287,0.126-0.348s0.279-0.005,0.34,0.124
						c0.061,0.129,0.004,0.285-0.126,0.348C72.173,146.169,72.021,146.114,71.964,145.983z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.67" cy="147.119" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M64.73,147.805c-0.011-0.142,0.094-0.264,0.234-0.271c0.141-0.003,0.267,0.1,0.281,0.238
						c0.016,0.145-0.088,0.274-0.233,0.278C64.868,148.057,64.742,147.947,64.73,147.805z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M60.986,147.805c0.012-0.142,0.136-0.252,0.276-0.245c0.141,0.007,0.248,0.129,0.24,0.271
						c-0.008,0.143-0.132,0.252-0.276,0.245C61.082,148.069,60.974,147.947,60.986,147.805z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M57.296,147.184c0.035-0.138,0.175-0.222,0.311-0.187s0.221,0.177,0.19,0.316
						c-0.032,0.14-0.171,0.223-0.311,0.188C57.346,147.464,57.261,147.322,57.296,147.184z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M53.752,145.983c0.057-0.13,0.207-0.187,0.334-0.126c0.128,0.058,0.194,0.198,0.14,0.332
						c-0.054,0.13-0.21,0.204-0.342,0.145C53.753,146.271,53.694,146.114,53.752,145.983z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M50.461,144.198c0.078-0.119,0.24-0.157,0.36-0.084c0.12,0.073,0.156,0.232,0.082,0.353
						c-0.075,0.122-0.236,0.159-0.36,0.084C50.419,144.475,50.382,144.317,50.461,144.198z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="47.708" cy="142.062" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M44.979,139.138c0.112-0.087,0.272-0.067,0.357,0.046s0.062,0.278-0.047,0.368
						c-0.111,0.091-0.271,0.07-0.357-0.045C44.845,139.391,44.866,139.226,44.979,139.138z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M42.911,136.017c0.122-0.066,0.286-0.025,0.359,0.096c0.073,0.12,0.031,0.276-0.092,0.347
							c-0.124,0.071-0.285,0.029-0.36-0.095C42.743,136.241,42.782,136.086,42.911,136.017z" />
                </g>
                <g>
                  <a id="p50" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="41.514" cy="132.823" r="0.258" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M40.514,128.952c0.14-0.023,0.272,0.071,0.293,0.21c0.021,0.139-0.076,0.274-0.216,0.301
						c-0.14,0.027-0.272-0.067-0.293-0.21C40.276,129.109,40.372,128.975,40.514,128.952z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M40.181,125.222c0.142,0,0.265,0.115,0.272,0.255c0.007,0.141-0.104,0.258-0.246,0.261
						c-0.143,0.003-0.265-0.111-0.271-0.255C39.928,125.339,40.038,125.222,40.181,125.222z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="40.475" cy="121.749" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M41.426,117.865c0.134,0.046,0.205,0.192,0.157,0.324c-0.048,0.132-0.199,0.204-0.334,0.161
						c-0.137-0.043-0.207-0.188-0.157-0.324C41.141,117.891,41.291,117.818,41.426,117.865z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M42.911,114.428c0.125,0.068,0.178,0.227,0.117,0.354c-0.061,0.126-0.214,0.176-0.341,0.112
						c-0.127-0.065-0.18-0.223-0.117-0.354C42.633,114.41,42.786,114.359,42.911,114.428z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="44.824" cy="111.514" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M47.517,108.557c0.096,0.104,0.09,0.267-0.014,0.362c-0.104,0.094-0.27,0.087-0.369-0.015
						c-0.1-0.103-0.093-0.265,0.014-0.362S47.42,108.452,47.517,108.557z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M50.461,106.247c0.078,0.119,0.043,0.276-0.077,0.349c-0.12,0.073-0.272,0.054-0.355-0.066
						c-0.08-0.115-0.057-0.289,0.067-0.364S50.383,106.127,50.461,106.247z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M53.752,104.462c0.057,0.13,0.001,0.287-0.126,0.348c-0.127,0.062-0.279,0.005-0.34-0.124
						c-0.061-0.129-0.004-0.285,0.126-0.348C53.543,104.275,53.695,104.331,53.752,104.462z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="57.046" cy="103.325" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M60.987,102.64c0.011,0.142-0.094,0.264-0.234,0.271c-0.141,0.003-0.267-0.099-0.282-0.238
						c-0.015-0.145,0.089-0.273,0.233-0.277C60.849,102.388,60.975,102.497,60.987,102.64z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M64.73,102.64c-0.012,0.142-0.136,0.252-0.276,0.245c-0.141-0.007-0.248-0.129-0.24-0.271
						c0.008-0.143,0.132-0.252,0.276-0.245C64.635,102.376,64.742,102.498,64.73,102.64z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M68.42,103.261c-0.035,0.138-0.174,0.222-0.311,0.187s-0.221-0.177-0.19-0.316
						c0.031-0.14,0.171-0.223,0.311-0.187C68.37,102.981,68.456,103.123,68.42,103.261z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M71.964,104.462c-0.057,0.13-0.208,0.187-0.334,0.126c-0.128-0.058-0.194-0.198-0.139-0.332
						c0.053-0.13,0.21-0.204,0.342-0.145C71.963,104.174,72.022,104.331,71.964,104.462z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M75.256,106.247c-0.078,0.119-0.24,0.157-0.36,0.084c-0.121-0.073-0.157-0.231-0.082-0.353
						c0.075-0.122,0.236-0.159,0.36-0.084C75.297,105.97,75.334,106.128,75.256,106.247z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="78.009" cy="108.383" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M80.738,111.307c-0.112,0.087-0.273,0.067-0.357-0.046c-0.084-0.112-0.062-0.278,0.048-0.368
						c0.11-0.091,0.271-0.07,0.357,0.045C80.872,111.054,80.851,111.219,80.738,111.307z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M82.806,114.428c-0.122,0.066-0.286,0.025-0.359-0.096c-0.073-0.12-0.031-0.276,0.092-0.347
						c0.124-0.071,0.285-0.028,0.36,0.095C82.973,114.205,82.935,114.358,82.806,114.428z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.203" cy="117.622" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M85.203,121.493c-0.14,0.023-0.272-0.071-0.293-0.21c-0.021-0.139,0.076-0.274,0.216-0.3
						c0.14-0.027,0.271,0.067,0.293,0.21C85.441,121.335,85.344,121.47,85.203,121.493z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M85.536,125.223c-0.142,0-0.265-0.115-0.272-0.255c-0.007-0.141,0.104-0.257,0.246-0.261
						c0.143-0.003,0.264,0.111,0.271,0.255S85.679,125.223,85.536,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="209.967" cy="128.697" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.016,132.58c-0.134-0.046-0.206-0.191-0.157-0.324c0.049-0.132,0.199-0.204,0.335-0.162
						c0.136,0.043,0.207,0.188,0.157,0.324C209.301,132.555,209.151,132.627,209.016,132.58z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M207.53,136.017c-0.125-0.068-0.178-0.227-0.117-0.354s0.215-0.177,0.341-0.112
						c0.127,0.065,0.179,0.224,0.117,0.354C207.809,136.035,207.656,136.086,207.53,136.017z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="205.617" cy="138.932" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M202.925,141.888c-0.096-0.105-0.09-0.268,0.014-0.362c0.104-0.095,0.27-0.087,0.369,0.015
						c0.1,0.103,0.093,0.265-0.014,0.362C203.187,142,203.021,141.994,202.925,141.888z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M199.98,144.198c-0.078-0.119-0.043-0.276,0.077-0.35c0.12-0.073,0.272-0.054,0.355,0.066
						c0.08,0.115,0.057,0.289-0.067,0.364C200.222,144.354,200.058,144.318,199.98,144.198z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M196.689,145.983c-0.057-0.13-0.001-0.287,0.126-0.348s0.279-0.005,0.34,0.124
						c0.061,0.129,0.004,0.285-0.126,0.348C196.898,146.169,196.746,146.114,196.689,145.983z" />
                </g>
                <g>
                  <a id="p51" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="193.395" cy="147.119" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M189.455,147.805c-0.011-0.142,0.094-0.264,0.234-0.271c0.141-0.003,0.267,0.1,0.281,0.238
						c0.016,0.145-0.088,0.274-0.233,0.278C189.593,148.057,189.466,147.947,189.455,147.805z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M185.711,147.805c0.012-0.142,0.136-0.252,0.276-0.245c0.141,0.007,0.248,0.129,0.24,0.271
							c-0.008,0.143-0.132,0.252-0.276,0.245C185.807,148.069,185.699,147.947,185.711,147.805z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M182.021,147.184c0.035-0.138,0.175-0.222,0.311-0.187s0.221,0.177,0.19,0.316
						c-0.032,0.14-0.171,0.223-0.311,0.188C182.071,147.464,181.986,147.322,182.021,147.184z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M178.477,145.983c0.057-0.13,0.207-0.187,0.334-0.126c0.128,0.058,0.194,0.198,0.14,0.332
						c-0.054,0.13-0.21,0.204-0.342,0.145C178.478,146.271,178.419,146.114,178.477,145.983z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M175.186,144.198c0.078-0.119,0.24-0.157,0.36-0.084c0.12,0.073,0.156,0.232,0.082,0.353
						c-0.075,0.122-0.236,0.159-0.36,0.084C175.144,144.475,175.107,144.317,175.186,144.198z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.432" cy="142.062" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M169.704,139.138c0.112-0.087,0.272-0.067,0.357,0.046s0.062,0.278-0.047,0.368
						c-0.111,0.091-0.271,0.07-0.357-0.045C169.569,139.391,169.59,139.226,169.704,139.138z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M167.636,136.017c0.122-0.066,0.286-0.025,0.359,0.096c0.073,0.12,0.031,0.276-0.092,0.347
						c-0.124,0.071-0.286,0.029-0.36-0.095C167.468,136.241,167.507,136.086,167.636,136.017z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.239" cy="132.823" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M165.238,128.952c0.14-0.023,0.272,0.071,0.293,0.21c0.021,0.139-0.076,0.274-0.216,0.301
						c-0.14,0.027-0.272-0.067-0.293-0.21C165,129.109,165.097,128.975,165.238,128.952z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M164.906,125.222c0.142,0,0.265,0.115,0.272,0.255c0.007,0.141-0.104,0.258-0.246,0.261
						c-0.143,0.003-0.264-0.111-0.271-0.255S164.763,125.222,164.906,125.222z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="165.199" cy="121.749" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M166.15,117.865c0.134,0.046,0.205,0.192,0.157,0.324c-0.048,0.132-0.199,0.205-0.334,0.162
						c-0.137-0.043-0.207-0.188-0.157-0.324C165.865,117.891,166.015,117.818,166.15,117.865z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M167.636,114.428c0.125,0.068,0.178,0.227,0.117,0.354c-0.061,0.126-0.214,0.176-0.341,0.112
						c-0.127-0.065-0.18-0.223-0.117-0.354C167.357,114.41,167.51,114.359,167.636,114.428z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="169.549" cy="111.514" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M172.241,108.557c0.096,0.105,0.091,0.268-0.014,0.362c-0.104,0.095-0.27,0.088-0.369-0.014
						c-0.1-0.103-0.093-0.265,0.014-0.362S172.145,108.452,172.241,108.557z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M175.186,106.247c0.078,0.119,0.043,0.276-0.077,0.349c-0.12,0.073-0.272,0.054-0.355-0.066
						c-0.08-0.115-0.057-0.289,0.067-0.364C174.944,106.091,175.108,106.127,175.186,106.247z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M178.477,104.462c0.057,0.13,0.001,0.287-0.126,0.348c-0.127,0.062-0.279,0.005-0.34-0.124
						c-0.061-0.129-0.004-0.285,0.126-0.348C178.268,104.275,178.42,104.331,178.477,104.462z" />
                </g>
                <g>
                  <a id="p52" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="181.771" cy="103.325" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M185.711,102.64c0.011,0.142-0.094,0.264-0.234,0.271c-0.141,0.003-0.267-0.099-0.281-0.238
						c-0.016-0.145,0.088-0.273,0.233-0.277C185.573,102.388,185.7,102.497,185.711,102.64z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M189.455,102.64c-0.012,0.142-0.136,0.252-0.276,0.245c-0.141-0.007-0.248-0.129-0.24-0.271
							c0.008-0.143,0.132-0.252,0.276-0.245C189.359,102.376,189.467,102.498,189.455,102.64z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M193.145,103.261c-0.035,0.138-0.175,0.222-0.311,0.187s-0.221-0.177-0.19-0.316
						c0.032-0.14,0.171-0.223,0.311-0.188C193.095,102.981,193.18,103.123,193.145,103.261z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M196.689,104.462c-0.057,0.13-0.207,0.187-0.334,0.126c-0.128-0.058-0.194-0.198-0.14-0.332
						c0.053-0.13,0.21-0.204,0.342-0.145C196.688,104.174,196.747,104.331,196.689,104.462z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M199.98,106.247c-0.078,0.119-0.24,0.157-0.36,0.084c-0.12-0.073-0.156-0.231-0.082-0.353
						c0.075-0.122,0.236-0.159,0.36-0.084C200.022,105.97,200.059,106.127,199.98,106.247z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="202.733" cy="108.383" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M205.462,111.307c-0.112,0.087-0.273,0.067-0.357-0.046c-0.084-0.112-0.062-0.278,0.047-0.368
						c0.11-0.091,0.271-0.07,0.357,0.045C205.597,111.054,205.576,111.219,205.462,111.307z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M207.53,114.428c-0.122,0.066-0.286,0.025-0.359-0.096c-0.073-0.12-0.031-0.276,0.092-0.347
						c0.124-0.071,0.285-0.029,0.36,0.095C207.698,114.205,207.659,114.358,207.53,114.428z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="208.927" cy="117.622" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M209.928,121.493c-0.14,0.023-0.272-0.071-0.293-0.21c-0.021-0.139,0.076-0.274,0.216-0.3
						c0.14-0.027,0.271,0.067,0.293,0.21S210.069,121.47,209.928,121.493z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M210.26,125.223c-0.142,0-0.265-0.115-0.272-0.255c-0.007-0.141,0.104-0.257,0.246-0.261
						c0.143-0.003,0.264,0.111,0.271,0.255S210.403,125.223,210.26,125.223z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="147.604" cy="66.334" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.653,70.218c-0.134-0.046-0.206-0.191-0.157-0.324c0.048-0.132,0.199-0.205,0.334-0.162
						c0.136,0.043,0.207,0.188,0.157,0.324C146.938,70.192,146.789,70.265,146.653,70.218z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M145.167,73.655c-0.125-0.068-0.178-0.227-0.117-0.354s0.215-0.177,0.341-0.112
						c0.127,0.065,0.18,0.224,0.117,0.354C145.446,73.673,145.293,73.724,145.167,73.655z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.254" cy="76.569" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M140.562,79.526c-0.096-0.105-0.09-0.268,0.014-0.362c0.104-0.095,0.27-0.087,0.369,0.015
						c0.1,0.103,0.093,0.265-0.014,0.362C140.824,79.638,140.659,79.631,140.562,79.526z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M137.618,81.836c-0.078-0.119-0.043-0.276,0.077-0.35c0.121-0.073,0.272-0.053,0.355,0.066
						c0.08,0.115,0.057,0.289-0.066,0.364S137.696,81.956,137.618,81.836z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M134.326,83.621c-0.057-0.13,0-0.287,0.126-0.348c0.126-0.061,0.279-0.005,0.339,0.124
						c0.061,0.129,0.004,0.285-0.126,0.348C134.536,83.807,134.384,83.752,134.326,83.621z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.032" cy="84.757" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M127.092,85.442c-0.011-0.142,0.094-0.264,0.234-0.271c0.141-0.003,0.267,0.1,0.281,0.238
						c0.016,0.145-0.088,0.274-0.233,0.278C127.23,85.695,127.104,85.585,127.092,85.442z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M123.349,85.442c0.012-0.142,0.136-0.252,0.276-0.245c0.141,0.007,0.248,0.129,0.24,0.271
						c-0.008,0.143-0.132,0.252-0.276,0.245S123.336,85.585,123.349,85.442z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M119.659,84.821c0.035-0.138,0.175-0.222,0.311-0.187s0.221,0.177,0.19,0.316
						c-0.032,0.14-0.171,0.223-0.311,0.188C119.708,85.102,119.624,84.96,119.659,84.821z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.114,83.621c0.058-0.13,0.208-0.187,0.334-0.126c0.128,0.058,0.194,0.198,0.14,0.332
						c-0.054,0.13-0.21,0.204-0.342,0.145C116.116,83.908,116.057,83.751,116.114,83.621z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M112.823,81.835c0.078-0.119,0.24-0.157,0.36-0.084c0.12,0.073,0.156,0.231,0.082,0.353
						c-0.075,0.122-0.237,0.159-0.36,0.084C112.781,82.113,112.745,81.955,112.823,81.835z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.07" cy="79.699" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M107.341,76.776c0.112-0.087,0.272-0.067,0.357,0.046s0.062,0.278-0.047,0.368
						c-0.111,0.091-0.271,0.07-0.357-0.045C107.207,77.029,107.228,76.864,107.341,76.776z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.273,73.655c0.122-0.066,0.286-0.025,0.359,0.096c0.073,0.12,0.031,0.276-0.092,0.347
						c-0.124,0.071-0.285,0.029-0.36-0.095C105.105,73.878,105.145,73.724,105.273,73.655z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="103.876" cy="70.46" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.876,66.589c0.14-0.023,0.272,0.071,0.293,0.21c0.021,0.139-0.076,0.274-0.216,0.301
						c-0.14,0.027-0.272-0.067-0.293-0.21C102.638,66.747,102.734,66.612,102.876,66.589z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.543,62.86c0.142,0,0.265,0.115,0.272,0.255c0.007,0.141-0.104,0.258-0.246,0.261
							c-0.143,0.003-0.265-0.111-0.271-0.255C102.291,62.977,102.4,62.86,102.543,62.86z" />
                </g>
                <g>
                  <a id="p53" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="102.837" cy="59.386" r="0.258" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M103.788,55.502c0.135,0.046,0.206,0.192,0.157,0.324c-0.048,0.132-0.199,0.204-0.334,0.161
						c-0.137-0.043-0.207-0.188-0.157-0.324C103.503,55.528,103.653,55.456,103.788,55.502z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.273,52.065c0.125,0.068,0.178,0.227,0.117,0.354c-0.061,0.126-0.214,0.176-0.341,0.112
						c-0.127-0.065-0.179-0.223-0.117-0.354C104.995,52.047,105.148,51.997,105.273,52.065z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.187" cy="49.151" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M109.879,46.195c0.096,0.104,0.09,0.268-0.014,0.362c-0.104,0.094-0.27,0.087-0.369-0.015
							c-0.1-0.103-0.093-0.265,0.014-0.362S109.782,46.089,109.879,46.195z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M112.824,43.885c0.077,0.119,0.043,0.276-0.077,0.349c-0.12,0.073-0.272,0.054-0.355-0.066
						c-0.08-0.115-0.057-0.289,0.067-0.364S112.746,43.765,112.824,43.885z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.115,42.1c0.057,0.13,0,0.287-0.126,0.348c-0.126,0.062-0.279,0.005-0.339-0.124
						c-0.061-0.129-0.004-0.285,0.126-0.348C115.905,41.913,116.058,41.968,116.115,42.1z" />
                </g>
                <g>
                  <a id="p54" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="119.409" cy="40.963" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M123.349,40.278c0.011,0.142-0.093,0.264-0.234,0.271c-0.141,0.003-0.267-0.099-0.282-0.238
						c-0.016-0.145,0.089-0.273,0.233-0.277C123.211,40.025,123.337,40.135,123.349,40.278z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M127.093,40.278c-0.012,0.141-0.136,0.252-0.277,0.245c-0.14-0.007-0.248-0.129-0.24-0.271
						c0.008-0.143,0.132-0.252,0.276-0.245S127.104,40.135,127.093,40.278z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M130.782,40.899c-0.035,0.138-0.174,0.222-0.311,0.187c-0.136-0.035-0.221-0.177-0.189-0.316
						c0.031-0.14,0.17-0.223,0.311-0.188C130.732,40.619,130.818,40.76,130.782,40.899z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M134.327,42.1c-0.058,0.13-0.208,0.187-0.334,0.126c-0.128-0.058-0.194-0.198-0.139-0.332
						c0.053-0.13,0.21-0.204,0.342-0.145C134.326,41.812,134.384,41.969,134.327,42.1z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M137.618,43.885c-0.078,0.119-0.24,0.157-0.36,0.084c-0.12-0.073-0.156-0.231-0.082-0.353
						c0.076-0.122,0.237-0.159,0.36-0.084S137.696,43.765,137.618,43.885z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.371" cy="46.021" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M143.1,48.944c-0.112,0.087-0.272,0.067-0.357-0.046c-0.084-0.112-0.062-0.278,0.047-0.368
						c0.111-0.091,0.271-0.07,0.357,0.045C143.234,48.692,143.213,48.856,143.1,48.944z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M145.167,52.066c-0.122,0.066-0.286,0.025-0.359-0.096c-0.073-0.12-0.031-0.276,0.092-0.347
						c0.124-0.071,0.285-0.029,0.36,0.095C145.335,51.842,145.296,51.996,145.167,52.066z" />
                </g>
                <g>
                  <a id="p55" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="146.564" cy="55.26" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M147.565,59.131c-0.14,0.023-0.272-0.071-0.293-0.21c-0.021-0.139,0.076-0.274,0.216-0.3
							c0.14-0.027,0.272,0.067,0.293,0.21C147.803,58.973,147.707,59.108,147.565,59.131z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M147.897,62.86c-0.142,0-0.265-0.115-0.272-0.255c-0.007-0.141,0.104-0.257,0.246-0.261
						c0.143-0.003,0.264,0.111,0.271,0.255S148.041,62.861,147.897,62.86z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="147.604" cy="191.059" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M146.653,194.942c-0.134-0.046-0.206-0.191-0.157-0.324c0.048-0.132,0.199-0.204,0.334-0.162
						c0.136,0.043,0.207,0.188,0.157,0.324C146.938,194.917,146.789,194.989,146.653,194.942z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M145.167,198.379c-0.125-0.068-0.178-0.227-0.117-0.354s0.215-0.177,0.341-0.112
						c0.127,0.065,0.179,0.224,0.117,0.354C145.446,198.397,145.293,198.448,145.167,198.379z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.254" cy="201.294" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M140.562,204.25c-0.096-0.104-0.09-0.268,0.014-0.362c0.104-0.095,0.27-0.087,0.369,0.015
						c0.1,0.103,0.093,0.265-0.014,0.362C140.824,204.362,140.659,204.356,140.562,204.25z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M137.618,206.561c-0.078-0.119-0.043-0.276,0.077-0.349c0.121-0.073,0.272-0.054,0.355,0.066
						c0.08,0.115,0.057,0.289-0.066,0.364S137.696,206.68,137.618,206.561z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M134.327,208.346c-0.057-0.13-0.001-0.287,0.126-0.348c0.127-0.062,0.279-0.005,0.34,0.123
						c0.061,0.13,0.004,0.286-0.126,0.348C134.536,208.532,134.384,208.477,134.327,208.346z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.032" cy="209.482" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M127.092,210.167c-0.011-0.142,0.094-0.264,0.234-0.271c0.141-0.003,0.267,0.099,0.281,0.238
						c0.016,0.145-0.088,0.273-0.233,0.277C127.23,210.42,127.104,210.311,127.092,210.167z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M123.349,210.167c0.012-0.142,0.136-0.252,0.276-0.245c0.141,0.007,0.248,0.129,0.24,0.271
						c-0.008,0.143-0.132,0.252-0.276,0.245C123.444,210.432,123.336,210.31,123.349,210.167z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M119.659,209.546c0.035-0.138,0.175-0.222,0.311-0.187s0.221,0.177,0.19,0.316
						c-0.032,0.14-0.171,0.223-0.311,0.187C119.708,209.827,119.624,209.685,119.659,209.546z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.115,208.346c0.057-0.13,0.207-0.187,0.334-0.126c0.128,0.058,0.194,0.198,0.14,0.332
						c-0.054,0.13-0.21,0.204-0.342,0.145C116.116,208.633,116.057,208.477,116.115,208.346z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M112.823,206.561c0.078-0.119,0.24-0.157,0.36-0.084c0.12,0.073,0.156,0.231,0.082,0.353
						c-0.075,0.121-0.236,0.159-0.36,0.083C112.781,206.837,112.745,206.68,112.823,206.561z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.07" cy="204.424" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M107.341,201.501c0.112-0.087,0.273-0.067,0.357,0.045c0.084,0.113,0.062,0.278-0.048,0.369
						c-0.11,0.091-0.271,0.07-0.357-0.046C107.207,201.753,107.229,201.588,107.341,201.501z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.273,198.379c0.122-0.066,0.286-0.025,0.359,0.096c0.073,0.121,0.031,0.276-0.092,0.347
						c-0.124,0.071-0.285,0.028-0.36-0.095C105.106,198.603,105.145,198.449,105.273,198.379z" />
                </g>
                <g>
                  <a id="p56" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="103.876" cy="195.185" r="0.259" />
                  </a>
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.876,191.314c0.14-0.023,0.272,0.071,0.293,0.21c0.021,0.139-0.076,0.274-0.216,0.301
						c-0.14,0.027-0.271-0.067-0.293-0.21C102.638,191.472,102.735,191.337,102.876,191.314z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M102.543,187.585c0.142,0,0.265,0.114,0.272,0.255c0.007,0.141-0.104,0.257-0.246,0.261
						c-0.143,0.003-0.264-0.111-0.271-0.255C102.291,187.702,102.4,187.584,102.543,187.585z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.837" cy="184.111" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M103.788,180.227c0.134,0.046,0.206,0.192,0.157,0.324c-0.049,0.132-0.199,0.204-0.335,0.162
						c-0.136-0.043-0.207-0.188-0.157-0.324S103.652,180.181,103.788,180.227z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M105.273,176.79c0.125,0.068,0.178,0.227,0.117,0.354s-0.215,0.177-0.341,0.112
						c-0.127-0.065-0.179-0.223-0.117-0.354C104.995,176.772,105.148,176.722,105.273,176.79z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.187" cy="173.876" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M109.879,170.919c0.096,0.104,0.09,0.268-0.014,0.362c-0.104,0.095-0.27,0.087-0.369-0.015
						c-0.099-0.103-0.093-0.265,0.014-0.362S109.782,170.814,109.879,170.919z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M112.823,168.609c0.078,0.119,0.044,0.276-0.077,0.349c-0.12,0.073-0.272,0.054-0.355-0.066
						c-0.08-0.115-0.057-0.289,0.066-0.364C112.582,168.454,112.745,168.49,112.823,168.609z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M116.115,166.824c0.057,0.13,0,0.287-0.126,0.348c-0.126,0.061-0.279,0.005-0.339-0.124
						c-0.061-0.13-0.004-0.285,0.126-0.348S116.057,166.693,116.115,166.824z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.409" cy="165.688" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M123.349,165.002c0.011,0.142-0.093,0.264-0.234,0.271c-0.141,0.003-0.267-0.099-0.281-0.238
						c-0.016-0.145,0.089-0.273,0.233-0.277C123.211,164.75,123.337,164.859,123.349,165.002z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M127.092,165.002c-0.012,0.141-0.136,0.252-0.276,0.245c-0.141-0.007-0.248-0.129-0.24-0.271
						c0.008-0.143,0.132-0.252,0.276-0.245S127.104,164.86,127.092,165.002z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M130.782,165.623c-0.035,0.138-0.174,0.223-0.311,0.188c-0.136-0.035-0.221-0.177-0.19-0.316
						c0.032-0.14,0.171-0.223,0.311-0.188C130.732,165.343,130.817,165.485,130.782,165.623z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M134.327,166.824c-0.058,0.13-0.208,0.187-0.334,0.126c-0.128-0.058-0.194-0.198-0.139-0.332
						c0.053-0.13,0.21-0.204,0.342-0.145C134.325,166.537,134.384,166.693,134.327,166.824z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M137.618,168.609c-0.078,0.119-0.24,0.157-0.36,0.084c-0.12-0.073-0.156-0.231-0.082-0.353
						c0.076-0.122,0.237-0.159,0.36-0.084S137.696,168.49,137.618,168.609z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.371" cy="170.746" r="0.258" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M143.1,173.669c-0.112,0.087-0.272,0.067-0.357-0.046c-0.084-0.113-0.062-0.278,0.047-0.368
						c0.111-0.091,0.271-0.07,0.357,0.045C143.234,173.416,143.213,173.581,143.1,173.669z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M145.167,176.791c-0.122,0.066-0.286,0.025-0.359-0.096c-0.073-0.12-0.031-0.276,0.092-0.347
						c0.124-0.071,0.285-0.029,0.36,0.095C145.335,176.567,145.296,176.721,145.167,176.791z" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="146.564" cy="179.985" r="0.259" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M147.565,183.855c-0.14,0.023-0.272-0.071-0.293-0.21c-0.021-0.139,0.076-0.274,0.216-0.3
						c0.14-0.027,0.272,0.067,0.293,0.21C147.803,183.698,147.707,183.833,147.565,183.855z" />
                </g>
                <g>
                  <path fill="#FFFFFF" d="M147.897,187.585c-0.142,0-0.265-0.115-0.272-0.255c-0.007-0.141,0.104-0.257,0.246-0.261
						c0.143-0.003,0.264,0.111,0.271,0.255S148.041,187.585,147.897,187.585z" />
                </g>
              </g>
            </g>
          </g>
          <g id="vierkanten">
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="178.138" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="174.358" r="0.26" />
                </g>
                <g>
                  <a id="p57" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="68.527" cy="170.578" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="166.799" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="163.019" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="159.239" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="155.46" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="151.68" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="147.9" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="144.121" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="140.341" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="136.562" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="132.782" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="129.002" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="125.222" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="121.443" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="117.663" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="113.883" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="110.104" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="106.324" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="102.544" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="98.765" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="94.985" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="91.205" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="87.426" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="83.646" r="0.261" />
                </g>
                <g>
                  <a id="p58" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="68.527" cy="79.866" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="76.087" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="68.527" cy="72.307" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.305" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.085" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.865" cy="68.529" r="0.26" />
                </g>
                <g>
                  <a id="p59" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="83.644" cy="68.529" r="0.261" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.424" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.204" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.983" cy="68.529" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.763" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.542" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="106.322" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.102" cy="68.529" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.881" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.661" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.441" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.22" cy="68.529" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.78" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.56" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.339" cy="68.529" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="144.119" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="147.898" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="151.678" cy="68.529" r="0.261" />
                </g>
                <g>
                  <a id="p60" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="155.458" cy="68.529" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.237" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.017" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.797" cy="68.529" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.576" cy="68.529" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="174.356" cy="68.529" r="0.261" />
                </g>
                <g>
                  <a id="p61" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="178.136" cy="68.529" r="0.261" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="72.307" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="76.087" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="79.866" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="83.646" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="87.426" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="91.205" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="94.985" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="98.765" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="102.544" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="106.324" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="110.104" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="113.883" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="117.663" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="121.443" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="125.222" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="129.002" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="132.782" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="136.562" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="140.341" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="144.121" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="147.9" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="151.68" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="155.46" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="159.239" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="163.019" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="166.799" r="0.261" />
                </g>
                <g>
                  <a id="p62" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="181.914" cy="170.578" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="174.358" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="181.914" cy="178.138" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="178.136" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="174.356" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.576" cy="181.916" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.797" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.017" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.237" cy="181.916" r="0.261" />
                </g>
                <g>
                  <a id="p63" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="155.458" cy="181.916" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="151.678" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="147.898" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="144.119" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.339" cy="181.916" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.56" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.78" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.22" cy="181.916" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.441" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.661" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.881" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.102" cy="181.916" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="106.322" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.542" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.763" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.983" cy="181.916" r="0.26" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="91.204" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.424" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.644" cy="181.916" r="0.261" />
                </g>
                <g>
                  <a id="p64" className="">
                    <circle fill="#FFFFFF" className="hoverable" cx="79.865" cy="181.916" r="0.26" />
                  </a>
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.085" cy="181.916" r="0.261" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.305" cy="181.916" r="0.261" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="81.583" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="80.304" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.026" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.748" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.47" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="75.192" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.914" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.636" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="138.162" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="136.929" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="135.697" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="134.465" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="133.233" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="132" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="130.768" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="129.535" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="128.303" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="127.071" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="125.839" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="124.606" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="123.374" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="122.141" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="120.909" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="119.677" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="118.445" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="117.212" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="115.98" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="114.748" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="113.515" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="71.362" cy="112.283" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="72.636" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="73.914" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="75.192" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="76.47" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="77.748" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="79.026" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="80.304" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.583" cy="111.049" r="0.16" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="168.859" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.137" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.415" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.693" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="173.971" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="175.249" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.527" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.805" cy="111.049" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="112.283" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="113.515" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="114.748" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="115.98" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="117.212" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="118.445" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="119.677" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="120.909" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="122.141" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="123.374" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="124.606" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="125.839" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="127.071" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="128.303" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="129.535" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="130.768" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="132" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="133.233" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="134.465" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="135.697" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="136.929" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="179.079" cy="138.162" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="177.805" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.527" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="175.249" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="173.971" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="172.693" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="171.415" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="170.137" cy="139.396" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="168.859" cy="139.396" r="0.16" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="81.584" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="80.306" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="79.028" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="77.75" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="76.472" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="75.194" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="73.916" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="72.638" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.16" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.927" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.695" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.463" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.231" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.998" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.766" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129.533" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.302" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.069" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.837" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="124.604" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.372" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.139" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="120.907" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.675" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.443" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.21" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.978" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="114.746" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.513" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.281" cy="71.364" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="72.638" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="73.916" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="75.194" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="76.472" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="77.75" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="79.028" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="80.306" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="81.584" r="0.16" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="168.861" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="170.139" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="171.417" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="172.695" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="173.973" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="175.251" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="176.529" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.394" cy="177.807" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.16" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.927" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.695" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.463" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.231" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.998" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.766" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129.533" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="128.302" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.069" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.837" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="124.604" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.372" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.139" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="120.907" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.675" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.443" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.21" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.978" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="114.746" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.513" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.281" cy="179.081" r="0.154" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="177.807" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="176.529" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="175.251" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="173.973" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="172.695" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="171.417" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="170.139" r="0.16" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="111.047" cy="168.861" r="0.16" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <ellipse fill="#FFFFFF" cx="78.724" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="77.596" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="76.467" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="75.339" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="115.142" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="116.402" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="117.662" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="118.922" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="120.182" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="121.442" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="122.702" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="123.962" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="125.222" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="126.483" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="127.742" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="129.003" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="130.263" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="131.523" r="0.158" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="132.783" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="134.043" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="74.197" cy="135.303" r="0.158" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="75.339" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="76.467" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="77.596" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="78.724" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="135.418" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="134.291" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="133.162" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="132.034" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.083" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="82.297" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.511" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.725" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="85.939" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.153" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="132.138" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="133.385" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="134.633" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="135.88" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="137.127" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="138.375" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="139.622" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="140.869" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="142.117" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="143.364" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="144.612" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="145.859" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="147.106" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="148.353" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="149.601" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="150.848" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="152.095" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="153.343" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="154.59" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="155.837" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="157.085" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="158.332" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="159.579" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="160.827" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="89.617" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="90.864" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="92.111" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="93.359" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.606" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="95.853" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="97.101" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.348" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="99.595" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.843" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.09" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="103.337" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.584" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="105.832" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.079" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.326" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="109.573" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.821" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.068" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.315" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="114.563" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.81" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.057" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.305" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="163.291" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="164.504" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="165.718" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="166.932" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="168.146" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="169.36" r="0.152" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="118.409" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="117.281" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="116.152" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="115.024" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="171.719" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="172.848" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="173.976" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="175.104" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.14" cy="176.246" r="0.158" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.4" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.66" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.92" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="120.18" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.44" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.7" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.96" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.22" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="126.481" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.74" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129.001" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.261" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.521" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.781" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.041" cy="176.246" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.301" cy="176.246" r="0.157" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="175.104" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="173.976" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="172.848" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="171.719" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="135.417" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="134.289" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="133.16" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="132.032" cy="170.577" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="169.36" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="168.146" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="166.932" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="165.718" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="164.504" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="163.291" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.136" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.384" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.631" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.878" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.126" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.373" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.62" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.867" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="142.115" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.362" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="144.61" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.857" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="147.104" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="148.351" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.599" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="150.846" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.094" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="153.341" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="154.588" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.835" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.083" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="158.33" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.577" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="160.824" cy="162.073" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="160.827" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="159.579" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="158.332" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="157.085" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="155.837" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="154.59" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="153.343" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="152.095" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="150.848" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="149.601" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="148.353" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="147.106" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="145.859" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="144.612" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="143.364" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="142.117" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="140.869" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="139.622" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="138.375" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="137.127" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="135.88" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="134.633" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="133.385" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="132.138" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.289" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="164.502" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="165.716" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.93" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="168.144" cy="130.892" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="169.358" cy="130.892" r="0.152" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="132.034" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="133.162" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="134.291" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="135.418" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="171.718" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="172.846" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="173.974" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="175.102" cy="136.561" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="135.303" r="0.158" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="134.043" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="132.783" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="131.523" r="0.158" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="130.263" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="129.003" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="127.742" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="126.483" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="125.222" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="123.962" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="122.702" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="121.442" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="120.182" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="118.922" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="117.662" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="116.402" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="176.245" cy="115.142" r="0.157" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="175.102" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="173.974" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="172.846" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="171.718" cy="113.884" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="115.026" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="116.154" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="117.283" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="170.575" cy="118.411" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="169.358" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="168.144" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="166.93" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="165.716" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="164.502" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="163.289" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="118.306" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="117.059" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="115.812" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="114.565" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="113.317" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="112.07" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="110.823" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="109.575" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="108.328" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="107.081" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="105.833" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="104.586" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="103.339" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="102.092" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="100.844" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="99.597" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="98.35" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="97.103" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="95.855" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="94.608" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="93.361" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="92.113" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="90.866" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="162.071" cy="89.619" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="160.825" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="159.577" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="158.33" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="157.083" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="155.835" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="154.588" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="153.341" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.094" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="150.846" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.599" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="148.352" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="147.104" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.857" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="144.61" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="143.363" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="142.115" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="140.868" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="139.621" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.373" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="137.126" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.879" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.631" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="133.384" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.137" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="87.155" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="85.941" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="84.727" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="83.513" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="82.299" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.89" cy="81.085" r="0.152" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="132.032" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="133.161" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="134.289" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="135.417" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="78.726" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="77.598" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="76.469" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="136.559" cy="75.341" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="135.301" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.041" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="132.781" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="131.521" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.261" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="129.001" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.74" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="126.481" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="125.22" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.96" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="122.7" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="121.44" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="120.18" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.92" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.66" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="116.4" cy="74.199" r="0.157" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.14" cy="74.199" r="0.158" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="75.341" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="76.469" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="77.598" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="113.882" cy="78.726" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="115.024" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="116.152" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="117.281" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="118.409" cy="79.868" rx="0.141" ry="0.155" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="81.085" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="82.299" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="83.513" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="84.727" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="85.941" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.551" cy="87.155" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="118.305" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="117.057" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.81" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="114.563" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.315" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.068" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="110.821" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="109.573" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.326" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="107.079" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="105.832" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.584" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="103.337" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="102.09" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="100.843" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="99.595" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="98.348" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="97.101" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="95.853" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="94.606" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="93.359" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="92.111" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="90.864" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="89.617" cy="88.372" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="89.619" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="90.866" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="92.113" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="93.361" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="94.608" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="95.855" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="97.103" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="98.35" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="99.597" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="100.844" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="102.092" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="103.339" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="104.586" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="105.833" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="107.081" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="108.328" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="109.575" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="110.823" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="112.07" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="113.317" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="114.565" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="115.812" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="117.059" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="88.37" cy="118.306" r="0.156" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="87.153" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="85.939" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="84.725" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="83.511" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="82.297" cy="119.553" r="0.152" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="81.083" cy="119.553" r="0.152" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="118.411" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="117.283" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="116.154" rx="0.155" ry="0.141" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="79.866" cy="115.026" rx="0.155" ry="0.141" />
                </g>
              </g>
            </g>
          </g>
          <g id="kruis">
            <g>
              <g>
                <g>
                  <circle fill="#E0E0E0" cx="97.483" cy="113.884" r="0.255" d="M5 20 l215 0" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="101.181" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.88" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.578" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.276" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.975" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.673" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.372" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.07" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.768" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.467" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.165" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="141.864" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.562" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.26" cy="113.884" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.958" cy="113.884" r="0.255" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="97.483" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="101.181" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="104.88" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="108.578" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="112.276" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="115.975" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="119.673" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="123.372" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="127.07" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="130.768" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="134.467" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="138.165" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="141.864" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="145.562" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="149.26" cy="136.561" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="152.958" cy="136.561" r="0.255" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="97.485" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="101.183" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="104.882" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="108.58" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="112.278" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="115.977" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="119.675" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="123.373" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="127.072" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="130.77" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="134.468" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="138.167" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="141.865" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="145.563" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="149.262" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="113.882" cy="152.96" r="0.255" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="152.96" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="149.262" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="145.563" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="141.865" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="138.167" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="134.468" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="130.77" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="127.072" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="123.373" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="119.675" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="115.977" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="112.278" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="108.58" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="104.882" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="101.183" r="0.255" />
                </g>
                <g>
                  <circle fill="#FFFFFF" cx="136.559" cy="97.485" r="0.255" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.4472 -0.8944 0.8944 0.4472 -42.4564 147.7339)" fill="#FFFFFF" cx="98.291" cy="108.215"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.8946 0.4468 -0.4468 -0.8946 248.9408 137.881)" fill="#FFFFFF" cx="108.213" cy="98.293"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.8946 0.4468 -0.4468 0.8946 58.9032 -53.1906)" fill="#FFFFFF" cx="142.228" cy="98.293"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.4472 -0.8944 0.8944 -0.4472 123.4024 292.6966)" fill="#FFFFFF" cx="152.149" cy="108.215"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.4472 0.8944 -0.8944 -0.4472 347.4075 69.7513)" fill="#FFFFFF" cx="152.149" cy="142.23"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.8946 -0.4468 0.4468 0.8946 -52.9952 79.5788)" fill="#FFFFFF" cx="142.228" cy="152.152"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.8946 -0.4468 0.4468 -0.8946 137.0423 336.6214)" fill="#FFFFFF" cx="108.213" cy="152.152"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.4472 0.8944 -0.8944 0.4472 181.5488 -9.2911)" fill="#FFFFFF" cx="98.291" cy="142.23"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 279.7344 179.7269)" fill="#FFFFFF" cx="102.645" cy="147.798"
                    rx="0.287" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 276.7986 186.8145)" fill="#FFFFFF" cx="99.709" cy="150.734"
                    rx="0.286" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 273.8628 193.9022)" fill="#FFFFFF" cx="96.773" cy="153.67"
                    rx="0.287" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 153.6674 370.991)" fill="#FFFFFF" cx="153.668" cy="153.67"
                    rx="0.287" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7075 -0.7067 0.7067 -0.7075 150.8597 363.9031)" fill="#FFFFFF" cx="150.732" cy="150.734"
                    rx="0.286" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 147.795 356.8154)" fill="#FFFFFF" cx="147.796" cy="147.798"
                    rx="0.287" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 330.7587 56.5451)" fill="#FFFFFF" cx="153.668" cy="96.775"
                    rx="0.287" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7075 0.7067 -0.7067 -0.7075 327.8441 63.7396)" fill="#FFFFFF" cx="150.732" cy="99.711"
                    rx="0.286" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 324.8862 70.7207)" fill="#FFFFFF" cx="147.796" cy="102.646"
                    rx="0.287" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.7071 0.7071 -0.7071 0.7071 96.7743 -40.0841)" fill="#FFFFFF" cx="96.773" cy="96.775"
                    rx="0.287" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(0.7071 0.7071 -0.7071 0.7071 99.7101 -41.3001)" fill="#FFFFFF" cx="99.709" cy="99.711"
                    rx="0.286" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(0.7071 0.7071 -0.7071 0.7071 102.6459 -42.5162)" fill="#FFFFFF" cx="102.645" cy="102.646"
                    rx="0.287" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -30.0641 112.6668)" fill="#FFFFFF" cx="120.969" cy="92.624"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.7071 0.7071 -0.7071 0.7071 103.4167 -64.422)" fill="#FFFFFF" cx="129.473" cy="92.624"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 94.9104 354.9548)" fill="#FFFFFF" cx="120.969" cy="157.821"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -73.6745 137.7756)" fill="#FFFFFF" cx="129.473" cy="157.821"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 185.8795 313.2663)" fill="#FFFFFF" cx="157.819" cy="118.136"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.3324 150.3476)" fill="#FFFFFF" cx="157.819" cy="132.309"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 241.6507 136.1775)" fill="#FFFFFF" cx="92.622" cy="118.136"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 64.5591 291.3594)" fill="#FFFFFF" cx="92.622" cy="132.309"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.8946 -0.4468 0.4468 0.8946 -44.8101 57.5944)" fill="#FFFFFF" cx="99.709" cy="123.805"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <ellipse fill="#FFFFFF" cx="105.378" cy="122.388" rx="0.21" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.8946 0.4468 -0.4468 0.8946 67.0884 -31.2062)" fill="#FFFFFF" cx="99.709" cy="126.64"
                    rx="0.235" ry="0.258" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <ellipse fill="#FFFFFF" cx="105.378" cy="128.057" rx="0.21" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 177.0314 320.9391)" fill="#FFFFFF" cx="154.984" cy="123.805"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 354.123 106.5977)" fill="#FFFFFF" cx="154.984" cy="126.64"
                    rx="0.148" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.9489 -0.3155 0.3155 -0.9489 253.7792 295.3596)" fill="#FFFFFF" cx="150.801" cy="127.135"
                    rx="0.216" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.9486 -0.3166 0.3166 -0.9486 248.1227 292.5994)" fill="#FFFFFF" cx="147.829" cy="126.145"
                    rx="0.216" ry="0.258" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.9486 0.3166 -0.3166 -0.9486 332.8813 192.5398)" fill="#FFFFFF" cx="150.801" cy="123.31"
                    rx="0.216" ry="0.259" />
                </g>
                <g>

                  <ellipse transform="matrix(-0.9486 0.3166 -0.3166 -0.9486 327.4052 195.4099)" fill="#FFFFFF" cx="147.829" cy="124.3"
                    rx="0.216" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(-0.4472 -0.8944 0.8944 -0.4472 94.3167 303.0663)" fill="#FFFFFF" cx="140.811" cy="122.388"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <ellipse fill="#FFFFFF" cx="139.394" cy="123.241" rx="0.259" ry="0.273" />
                </g>
                <g>
                  <ellipse fill="#FFFFFF" cx="139.394" cy="127.203" rx="0.259" ry="0.273" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>

                  <ellipse transform="matrix(0.4472 -0.8944 0.8944 0.4472 -36.6993 196.7335)" fill="#FFFFFF" cx="140.811" cy="128.057"
                    rx="0.235" ry="0.259" />
                </g>
              </g>
            </g>
          </g>
          <g id="lijnen">
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="34.512" y1="125.223" x2="45.85" y2="122.388" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="34.512" y1="125.223" x2="45.85" y2="128.057" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="57.189" y1="130.892" x2="54.354" y2="139.396" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="57.189" y1="119.553" x2="54.354" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="60.023" y1="105.38" x2="62.858" y2="119.553" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="62.858" y1="130.892" x2="60.023" y2="145.065" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="57.189" y1="125.223" x2="68.527" y2="139.396" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="57.189" y1="125.223" x2="68.527" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="68.527" y1="119.553" x2="54.354" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="68.527" y1="130.892" x2="54.354" y2="139.396" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="45.85" y1="139.396" x2="57.189" y2="130.892" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="57.189" y1="119.553" x2="45.85" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="45.85" y1="122.388" x2="60.023" y2="105.38" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="45.85" y1="128.057" x2="60.023" y2="145.065" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="68.527" y1="125.223" x2="79.866" y2="170.577" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="68.527" y1="125.223" x2="79.866" y2="79.868" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="91.207" x2="77.031" y2="153.569" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="159.238" x2="77.031" y2="96.876" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="48.685" y1="94.042" x2="77.031" y2="85.537" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="48.685" y1="156.403" x2="77.031" y2="164.908" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="48.685" y1="94.042" x2="79.866" y2="147.899" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="48.685" y1="156.403" x2="79.866" y2="102.545" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="122.388" x2="215.93" y2="125.223" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="128.057" x2="215.93" y2="125.223" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="122.388" x2="190.417" y2="105.38" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="128.057" x2="190.417" y2="145.065" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="196.087" y1="139.396" x2="193.252" y2="130.892" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="196.087" y1="111.049" x2="193.252" y2="119.553" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="190.417" y1="105.38" x2="187.583" y2="119.553" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="187.583" y1="130.892" x2="190.417" y2="145.065" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="193.252" y1="130.892" x2="181.914" y2="139.396" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="193.252" y1="119.553" x2="181.914" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="111.049" x2="193.252" y2="119.553" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="204.591" y1="139.396" x2="193.252" y2="130.892" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="181.914" y1="130.892" x2="196.087" y2="139.396" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="181.914" y1="119.553" x2="196.087" y2="111.049" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="201.756" y1="94.042" x2="173.41" y2="85.537" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="201.756" y1="156.403" x2="173.41" y2="164.908" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="79.868" x2="181.914" y2="125.223" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="181.914" y1="125.223" x2="170.575" y2="170.577" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="159.238" x2="173.41" y2="96.876" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="173.41" y1="153.569" x2="170.575" y2="91.207" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="102.545" x2="201.756" y2="156.403" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="147.899" x2="201.756" y2="94.042" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="119.551" y1="45.852" x2="125.221" y2="37.348" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="125.221" y1="37.348" x2="130.89" y2="45.852" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="79.868" x2="119.551" y2="54.356" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="130.89" y1="54.356" x2="170.575" y2="79.868" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="116.717" y1="62.86" x2="102.543" y2="45.852" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="133.725" y1="62.86" x2="147.897" y2="45.852" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="119.551" y1="45.852" x2="136.559" y2="65.695" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="130.89" y1="45.852" x2="113.882" y2="65.695" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="116.717" y1="65.695" x2="130.89" y2="54.356" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="133.725" y1="65.695" x2="119.551" y2="54.356" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="116.717" y1="60.025" x2="125.221" y2="37.348" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="125.221" y1="37.348" x2="133.725" y2="60.025" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="125.221" y1="213.097" x2="108.212" y2="196.089" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="142.229" y1="196.089" x2="125.221" y2="213.097" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="108.212" y1="201.758" x2="139.394" y2="193.254" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="142.229" y1="201.758" x2="111.047" y2="193.254" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="111.047" y1="181.916" x2="125.221" y2="201.758" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="125.221" y1="201.758" x2="139.394" y2="181.916" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="111.047" y1="181.916" x2="102.543" y2="187.585" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="139.394" y1="181.916" x2="147.897" y2="187.585" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="170.577" x2="139.394" y2="190.419" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="170.577" x2="111.047" y2="190.419" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="79.868" x2="65.693" y2="65.695" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="79.868" x2="184.748" y2="65.695" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="170.577" x2="184.748" y2="184.75" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="170.577" x2="65.693" y2="184.75" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="79.866" y1="79.868" x2="170.575" y2="170.577" />
            <line fill="none" stroke="#636466" strokeWidth="0.25" x1="170.575" y1="79.868" x2="79.866" y2="170.577" />
          </g>
          <g id="punten">
            <a id="p65" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="34.512" cy="125.223" r="0.354" />
            </a>
            <circle fill="#FFFFFF" cx="79.866" cy="79.868" r="0.354" />
            <circle fill="#FFFFFF" cx="79.866" cy="170.577" r="0.354" />
            <a id="p66" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="170.575" cy="170.577" r="0.354" />
            </a>
            <a id="p67" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="170.575" cy="79.868" r="0.354" />
            </a>
            <circle fill="#FFFFFF" cx="215.929" cy="125.223" r="0.354" />
            <circle fill="#FFFFFF" cx="125.22" cy="213.097" r="0.354" />
            <circle fill="#FFFFFF" cx="125.22" cy="37.348" r="0.354" />
            <circle fill="#FFFFFF" cx="119.551" cy="45.852" r="0.354" />
            <circle fill="#FFFFFF" cx="130.89" cy="45.852" r="0.354" />
            <circle fill="#FFFFFF" cx="119.551" cy="54.356" r="0.354" />
            <a id="p68" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="130.89" cy="54.356" r="0.354" />
            </a>
            <circle fill="#FFFFFF" cx="116.717" cy="60.025" r="0.354" />
            <circle fill="#FFFFFF" cx="133.724" cy="60.025" r="0.354" />
            <circle fill="#FFFFFF" cx="133.724" cy="62.86" r="0.354" />
            <circle fill="#FFFFFF" cx="133.724" cy="65.695" r="0.354" />
            <circle fill="#FFFFFF" cx="136.559" cy="65.695" r="0.354" />
            <circle fill="#FFFFFF" cx="147.898" cy="45.852" r="0.354" />
            <circle fill="#FFFFFF" cx="102.543" cy="45.852" r="0.354" />
            <circle fill="#FFFFFF" cx="116.717" cy="62.86" r="0.354" />
            <circle fill="#FFFFFF" cx="116.717" cy="65.695" r="0.354" />
            <circle fill="#FFFFFF" cx="113.882" cy="65.695" r="0.354" />
            <a id="p69" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="125.22" cy="201.758" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="108.213" cy="201.758" r="0.283" />
            <circle fill="#FFFFFF" cx="141.945" cy="201.758" r="0.283" />
            <a id="p70" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="141.945" cy="196.089" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="108.213" cy="196.089" r="0.283" />
            <circle fill="#FFFFFF" cx="111.047" cy="193.254" r="0.283" />
            <circle fill="#FFFFFF" cx="111.047" cy="190.419" r="0.283" />
            <circle fill="#FFFFFF" cx="139.11" cy="190.419" r="0.283" />
            <circle fill="#FFFFFF" cx="147.923" cy="187.566" r="0.283" />
            <circle fill="#FFFFFF" cx="102.543" cy="187.584" r="0.283" />
            <circle fill="#FFFFFF" cx="111.047" cy="181.916" r="0.283" />
            <circle fill="#FFFFFF" cx="139.11" cy="181.916" r="0.283" />
            <circle fill="#FFFFFF" cx="139.11" cy="193.254" r="0.283" />
            <circle fill="#FFFFFF" cx="45.85" cy="122.388" r="0.283" />
            <circle fill="#FFFFFF" cx="45.85" cy="128.057" r="0.283" />
            <circle fill="#FFFFFF" cx="57.189" cy="119.553" r="0.283" />
            <circle fill="#FFFFFF" cx="62.858" cy="119.553" r="0.283" />
            <circle fill="#FFFFFF" cx="68.528" cy="119.553" r="0.283" />
            <circle fill="#FFFFFF" cx="68.528" cy="125.223" r="0.283" />
            <circle fill="#FFFFFF" cx="68.528" cy="130.892" r="0.283" />
            <circle fill="#FFFFFF" cx="62.858" cy="130.892" r="0.283" />
            <a id="p71" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="57.189" cy="125.223" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="57.189" cy="130.892" r="0.283" />
            <circle fill="#FFFFFF" cx="54.354" cy="111.049" r="0.283" />
            <circle fill="#FFFFFF" cx="54.354" cy="139.396" r="0.283" />
            <circle fill="#FFFFFF" cx="60.024" cy="145.065" r="0.283" />
            <circle fill="#FFFFFF" cx="60.024" cy="105.38" r="0.283" />
            <circle fill="#FFFFFF" cx="68.528" cy="111.049" r="0.283" />
            <circle fill="#FFFFFF" cx="68.528" cy="139.396" r="0.283" />
            <circle fill="#FFFFFF" cx="45.85" cy="139.396" r="0.283" />
            <a id="p72" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="48.685" cy="156.404" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="79.866" cy="147.9" r="0.283" />
            <circle fill="#FFFFFF" cx="77.031" cy="153.569" r="0.283" />
            <circle fill="#FFFFFF" cx="79.866" cy="159.238" r="0.283" />
            <circle fill="#FFFFFF" cx="77.031" cy="164.908" r="0.283" />
            <circle fill="#FFFFFF" cx="45.85" cy="111.049" r="0.283" />
            <circle fill="#FFFFFF" cx="77.031" cy="85.538" r="0.283" />
            <circle fill="#FFFFFF" cx="79.866" cy="91.207" r="0.283" />
            <circle fill="#FFFFFF" cx="77.031" cy="96.876" r="0.283" />
            <a id="p73" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="79.866" cy="102.546" r="0.283" />
            </a>
            <a id="p74" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="48.685" cy="94.042" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="204.547" cy="128.101" r="0.284" />
            <circle fill="#FFFFFF" cx="204.547" cy="122.432" r="0.284" />
            <a id="p75" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="193.208" cy="130.936" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="187.539" cy="130.936" r="0.283" />
            <circle fill="#FFFFFF" cx="181.87" cy="130.936" r="0.283" />
            <circle fill="#FFFFFF" cx="181.87" cy="125.267" r="0.283" />
            <circle fill="#FFFFFF" cx="181.87" cy="119.598" r="0.283" />
            <circle fill="#FFFFFF" cx="187.539" cy="119.598" r="0.283" />
            <circle fill="#FFFFFF" cx="193.208" cy="125.267" r="0.283" />
            <circle fill="#FFFFFF" cx="193.208" cy="119.597" r="0.283" />
            <circle fill="#FFFFFF" cx="196.043" cy="139.44" r="0.283" />
            <circle fill="#FFFFFF" cx="196.043" cy="111.094" r="0.283" />
            <circle fill="#FFFFFF" cx="190.373" cy="105.424" r="0.283" />
            <circle fill="#FFFFFF" cx="190.373" cy="145.109" r="0.283" />
            <circle fill="#FFFFFF" cx="181.869" cy="139.439" r="0.283" />
            <circle fill="#FFFFFF" cx="181.869" cy="111.093" r="0.283" />
            <circle fill="#FFFFFF" cx="204.547" cy="111.093" r="0.284" />
            <a id="p76" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="201.712" cy="94.085" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="170.531" cy="102.589" r="0.283" />
            <a id="p77" className="">
              <circle fill="#FFFFFF" className="hoverable" cx="173.365" cy="96.92" r="0.283" />
            </a>
            <circle fill="#FFFFFF" cx="170.531" cy="91.25" r="0.283" />
            <circle fill="#FFFFFF" cx="173.365" cy="85.581" r="0.283" />
            <circle fill="#FFFFFF" cx="204.547" cy="139.439" r="0.284" />
            <circle fill="#FFFFFF" cx="173.365" cy="164.952" r="0.283" />
            <circle fill="#FFFFFF" cx="170.531" cy="159.282" r="0.283" />
            <circle fill="#FFFFFF" cx="173.365" cy="153.613" r="0.283" />
            <circle fill="#FFFFFF" cx="170.531" cy="147.943" r="0.283" />
            <circle fill="#FFFFFF" cx="201.712" cy="156.448" r="0.283" />
          </g>
          <g id="Layer_11">
            <g>
              <g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						232.937,125.238 231.481,126.633 232.866,128.065 234.249,129.535 232.788,130.893 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						232.785,130.923 231.261,132.241 232.571,133.744 233.878,135.284 232.34,136.563 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						232.336,136.592 230.748,137.829 231.979,139.399 233.207,141.006 231.594,142.201 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						231.588,142.23 229.94,143.381 231.091,145.015 232.235,146.685 230.551,147.791 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						230.544,147.82 228.841,148.883 229.908,150.577 230.966,152.306 229.212,153.318 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						229.205,153.347 227.452,154.319 228.432,156.068 229.392,157.85 227.583,158.766 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						227.574,158.794 225.777,159.673 226.667,161.474 227.518,163.299 225.668,164.119 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						225.657,164.147 223.821,164.932 224.619,166.779 225.357,168.641 223.472,169.364 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						223.459,169.392 221.59,170.08 222.292,171.968 222.918,173.861 221.002,174.486 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						220.989,174.512 219.09,175.104 219.677,177.018 220.208,178.945 218.267,179.471 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						218.252,179.497 216.331,179.99 216.8,181.924 217.234,183.879 215.274,184.305 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						215.258,184.331 213.314,184.723 213.671,186.671 214.006,188.651 212.032,188.977 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						212.014,189.001 210.039,189.28 210.298,191.249 210.534,193.247 208.55,193.473 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						208.532,193.497 206.531,193.66 206.691,195.645 206.827,197.657 204.833,197.777 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						204.812,197.799 202.799,197.852 202.859,199.846 202.895,201.867 200.884,201.87 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						200.862,201.891 198.854,201.843 198.815,203.843 198.749,205.868 196.729,205.752 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						196.706,205.771 194.708,205.624 194.568,207.624 194.39,209.636 192.377,209.412 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						192.354,209.431 190.37,209.183 190.129,211.178 189.835,213.164 187.84,212.84 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						187.816,212.857 185.852,212.512 185.511,214.497 185.103,216.45 183.131,216.027 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						183.106,216.043 181.167,215.6 180.714,217.553 180.205,219.483 178.262,218.962 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						178.236,218.978 176.326,218.438 175.763,220.349 175.154,222.256 173.245,221.639 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						173.218,221.652 171.342,221.018 170.671,222.882 169.965,224.759 168.093,224.047 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						168.066,224.06 166.221,223.313 165.454,225.143 164.651,226.985 162.822,226.18 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						162.794,226.191 160.986,225.334 160.125,227.125 159.227,228.927 157.445,228.031 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						157.417,228.041 155.652,227.074 154.698,228.822 153.707,230.579 151.974,229.586 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						151.945,229.593 150.234,228.53 149.189,230.229 148.107,231.936 146.427,230.84 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						146.398,230.846 144.746,229.698 143.613,231.343 142.443,232.994 140.822,231.8 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						140.792,231.804 139.204,230.574 137.986,232.16 136.731,233.751 135.174,232.463 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						135.145,232.466 133.624,231.157 132.324,232.68 130.987,234.206 129.5,232.831 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						129.47,232.832 128.019,231.448 126.642,232.903 125.228,234.359 123.814,232.903 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						123.784,232.903 122.407,231.447 120.956,232.831 119.469,234.207 118.132,232.681 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						118.102,232.679 116.803,231.156 115.281,232.464 113.725,233.753 112.47,232.162 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						112.44,232.158 111.222,230.572 109.634,231.802 108.013,232.997 106.843,231.345 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						106.813,231.34 105.68,229.695 104.028,230.843 102.349,231.939 101.267,230.232 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						101.238,230.226 100.192,228.527 98.481,229.589 96.749,230.583 95.757,228.826 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						95.729,228.817 94.774,227.07 93.01,228.036 91.229,228.932 90.331,227.129 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						90.302,227.12 89.44,225.329 87.633,226.186 85.804,226.991 85.001,225.148 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						84.973,225.137 84.206,223.308 82.361,224.053 80.489,224.766 79.783,222.888 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						79.756,222.875 79.085,221.011 77.209,221.646 75.3,222.263 74.692,220.356 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						74.665,220.342 74.103,218.43 72.192,218.97 70.25,219.491 69.74,217.56 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						69.714,217.545 69.262,215.591 67.322,216.035 65.351,216.458 64.942,214.505 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						64.918,214.488 64.577,212.503 62.613,212.849 60.618,213.173 60.324,211.188 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						60.3,211.169 60.06,209.174 58.076,209.421 56.063,209.645 55.885,207.633 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						55.862,207.614 55.722,205.614 53.724,205.761 51.704,205.878 51.637,203.853 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						51.615,203.832 51.576,201.833 49.568,201.88 47.557,201.878 47.592,199.856 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						47.571,199.835 47.632,197.841 45.619,197.788 43.625,197.668 43.76,195.655 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						43.741,195.633 43.9,193.649 41.9,193.485 39.917,193.259 40.152,191.261 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						40.134,191.237 40.393,189.269 38.418,188.989 36.443,188.663 36.779,186.684 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						36.761,186.659 37.118,184.71 35.175,184.317 33.215,183.892 33.648,181.936 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						33.632,181.911 34.103,179.977 32.182,179.483 30.241,178.958 30.771,177.031 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						30.757,177.005 31.344,175.09 29.445,174.499 27.53,173.874 28.156,171.981 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						28.142,171.954 28.845,170.066 26.976,169.377 25.09,168.654 25.828,166.792 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						25.816,166.765 26.615,164.918 24.779,164.133 22.929,163.312 23.779,161.487 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						23.77,161.459 24.66,159.659 22.863,158.779 21.054,157.864 22.014,156.082 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						22.005,156.053 22.985,154.305 21.233,153.332 19.479,152.32 20.537,150.591 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						20.53,150.562 21.597,148.869 19.894,147.805 18.208,146.7 19.353,145.029 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						19.347,145 20.499,143.367 18.85,142.215 17.237,141.02 18.464,139.413 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						18.46,139.384 19.692,137.814 18.104,136.577 16.564,135.298 17.872,133.758 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						17.869,133.729 19.179,132.226 17.655,130.908 16.193,129.549 17.576,128.08 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						17.575,128.05 18.959,126.618 17.504,125.222 16.12,123.79 17.575,122.394 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						17.576,122.364 19.031,121.006 17.655,119.537 16.345,118.034 17.87,116.716 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						17.872,116.686 19.393,115.405 18.104,113.867 16.872,112.297 18.46,111.061 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						18.464,111.031 20.047,109.832 18.85,108.229 17.699,106.596 19.348,105.445 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						19.353,105.415 20.994,104.301 19.894,102.64 18.827,100.946 20.53,99.883 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						20.537,99.854 22.231,98.827 21.233,97.113 20.253,95.364 22.006,94.392 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						22.014,94.363 23.765,93.429 22.863,91.665 21.973,89.865 23.77,88.985 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						23.779,88.957 25.591,88.123 24.779,86.312 23.98,84.465 25.817,83.68 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						25.828,83.652 27.695,82.922 26.976,81.067 26.271,79.178 28.143,78.49 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						28.156,78.464 30.072,77.839 29.446,75.946 28.837,74.02 30.757,73.439 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						30.772,73.414 32.712,72.888 32.182,70.961 31.67,69.002 33.633,68.534 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						33.649,68.509 35.609,68.083 35.176,66.127 34.766,64.143 36.762,63.786 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						36.779,63.761 38.753,63.435 38.418,61.456 38.127,59.463 40.134,59.208 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						40.153,59.184 42.136,58.958 41.9,56.96 41.729,54.966 43.741,54.812 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						43.76,54.789 45.748,54.663 45.619,52.657 45.559,50.663 47.571,50.609 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						47.592,50.588 49.579,50.562 49.568,48.564 49.607,46.564 51.616,46.612 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						51.637,46.592 53.618,46.665 53.724,44.683 53.864,42.683 55.862,42.831 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						55.885,42.812 57.865,42.996 58.076,41.023 58.317,39.028 60.3,39.275 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						60.324,39.257 62.302,39.56 62.613,37.596 62.955,35.611 64.918,35.957 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						64.943,35.939 66.912,36.36 67.323,34.41 67.765,32.44 69.714,32.9 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						69.74,32.885 71.683,33.406 72.192,31.475 72.735,29.525 74.666,30.103 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						74.692,30.089 76.602,30.707 77.21,28.799 77.852,26.875 79.756,27.569 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						79.784,27.557 81.655,28.27 82.361,26.392 83.109,24.517 84.974,25.308 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						85.001,25.297 86.831,26.102 87.633,24.259 88.485,22.441 90.303,23.325 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						90.331,23.315 92.113,24.212 93.011,22.409 93.962,20.653 95.729,21.627 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						95.758,21.619 97.488,22.604 98.481,20.855 99.526,19.157 101.238,20.219 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						101.267,20.212 102.941,21.283 104.029,19.602 105.162,17.957 106.813,19.104 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						106.843,19.1 108.458,20.253 109.634,18.643 110.852,17.057 112.44,18.287 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						112.47,18.283 114.021,19.517 115.282,17.98 116.582,16.457 118.103,17.766 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						118.132,17.764 119.617,19.075 120.957,17.614 122.333,16.158 123.785,17.542 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						123.814,17.542 125.228,18.926 126.643,17.542 128.093,16.158 129.47,17.613 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						129.5,17.614 130.84,19.076 132.324,17.765 133.845,16.456 135.145,17.979 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						135.175,17.981 136.435,19.519 137.986,18.285 139.575,17.055 140.793,18.641 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						140.822,18.645 141.999,20.256 143.613,19.102 145.265,17.955 146.398,19.6 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						146.428,19.604 147.515,21.287 149.189,20.216 150.9,19.153 151.946,20.852 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						151.975,20.859 152.968,22.608 154.698,21.623 156.465,20.649 157.417,22.404 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						157.445,22.414 158.343,24.217 160.125,23.32 161.942,22.437 162.794,24.254 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						162.822,24.265 163.625,26.108 165.454,25.303 167.318,24.511 168.067,26.386 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						168.094,26.398 168.8,28.275 170.671,27.563 172.577,26.869 173.218,28.792 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						173.245,28.806 173.853,30.713 175.763,30.096 177.694,29.518 178.236,31.468 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						178.262,31.482 178.771,33.414 180.714,32.893 182.665,32.432 183.106,34.402 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						183.131,34.418 183.542,36.368 185.511,35.948 187.475,35.602 187.816,37.587 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						187.841,37.605 188.152,39.568 190.129,39.267 192.113,39.02 192.354,41.015 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						192.377,41.033 192.588,43.005 194.568,42.821 196.566,42.673 196.706,44.674 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						196.729,44.693 196.834,46.676 198.815,46.603 200.823,46.555 200.863,48.555 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						200.884,48.575 200.873,50.573 202.86,50.599 204.873,50.652 204.812,52.646 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						204.833,52.668 204.703,54.675 206.691,54.801 208.703,54.955 208.532,56.948 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						208.55,56.972 208.314,58.97 210.298,59.196 212.305,59.452 212.015,61.444 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						212.032,61.468 211.697,63.447 213.671,63.774 215.667,64.13 215.258,66.115 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						215.274,66.14 214.84,68.095 216.801,68.521 218.764,68.99 218.252,70.949 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						218.267,70.975 217.737,72.901 219.677,73.427 221.598,74.006 220.989,75.933 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						221.002,75.959 220.376,77.853 222.292,78.478 224.164,79.165 223.46,81.054 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						223.472,81.081 222.752,82.936 224.619,83.667 226.456,84.451 225.657,86.298 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						225.668,86.326 224.856,88.138 226.667,88.972 228.464,89.852 227.574,91.651 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						227.583,91.68 226.682,93.444 228.432,94.377 230.184,95.35 229.205,97.099 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						229.213,97.127 228.214,98.842 229.908,99.869 231.611,100.932 230.544,102.625 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						230.551,102.654 229.45,104.316 231.091,105.43 232.74,106.582 231.589,108.215 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						231.594,108.245 230.396,109.847 231.979,111.046 233.568,112.283 232.336,113.853 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						232.34,113.882 231.049,115.42 232.571,116.702 234.095,118.02 232.785,119.522 				" />
                </g>
                <g>
                  <polyline fill="none" stroke="#ACAEB1" strokeWidth="0.03" strokeLinecap="round" strokeLinejoin="round" points="
						232.788,119.552 231.411,121.021 232.866,122.38 234.321,123.775 232.937,125.208 				" />
                </g>
              </g>
            </g>
          </g>
          <g id="lines" className="tweenMax-lines" dangerouslySetInnerHTML={{__html: coords}}></g>
        </svg>
      </div>
    </div>
  )}

MandalaSvg.propTypes = {
  coords: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
};

export default withRouter(MandalaSvg);